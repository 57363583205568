import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PageMenuTabDto } from '../rest/model/pageMenuTabDto';

// Actions
export * from './app.actions';

// State
export interface State {
    pageMenuTabs: PageMenuTabDto[];
}

const getAppState = createFeatureSelector<State>('app');

export const getPageMenuTabs = createSelector(getAppState, (state) => state.pageMenuTabs);

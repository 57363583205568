import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TenantFeatureDto } from 'src/app/rest/model/tenantFeatureDto';
import * as fromShared from '../../shared/state';
import { Role } from './../../auth/_models/role';
import { AuthService } from './../../auth/_services/auth.service';

export enum FeatureFlags {
    useNewTimesheet = 'use_new_timesheet',

    SUBSCRIPTION_pro = 'SUBSCRIPTION_pro',
}

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagsService {
    public tenantFeatures$: Observable<TenantFeatureDto[]>;

    constructor(private store: Store<fromShared.SharedState>, private authService: AuthService) {
        this.tenantFeatures$ = this.store.select(fromShared.getTenantFeatures);
    }

    handleFeatureFlagChanged(featureId: string, isEnabled: boolean, isEnabledForAdmins: boolean) {
        switch (featureId) {
            default:
                break;
        }
    }

    isFeatureEnabled(featureId: string): Observable<boolean> {
        const isAdmin = this.authService.currentUserValue?.role == Role.Admin;
        return this.tenantFeatures$.pipe(
            map((tf) => {
                const feature = tf.find((f) => f.featureId === featureId);
                return feature && ((feature.isEnabledForAdmins && isAdmin) || feature.isEnabled);
            })
        );
    }
}

import { PayloadAction, ILanguage, OriginAction } from 'src/app/state/payload.interfaces';
import { FooterSetting } from '../_models/footer-setting';

export enum FrameActionTypes {
  LanguageChanged = '[Frame] Language Changed',

  LoadFooterSettings = '[Shared] Load Footer Settings',
  LoadFooterSettingsSuccess = '[Shared] Load Footer Settings Success',
  LoadFooterSettingsFailed = '[Shared] Load Footer Settings Failed'
}

export class LanguageChanged implements PayloadAction<ILanguage> {
  readonly type = FrameActionTypes.LanguageChanged;
  constructor(public origin: string, public payload: ILanguage) {}
}

export class LoadFooterSettings implements OriginAction {
  readonly type = FrameActionTypes.LoadFooterSettings;
  constructor(public origin: string) {}
}
export class LoadFooterSettingsSuccess
  implements PayloadAction<FooterSetting> {
  readonly type = FrameActionTypes.LoadFooterSettingsSuccess;
  constructor(public origin: string, public payload: FooterSetting) {}
}
export class LoadFooterSettingsFailed implements OriginAction {
  readonly type = FrameActionTypes.LoadFooterSettingsFailed;
  constructor(public origin: string) {}
}

export type FrameActions =
  | LanguageChanged
  | LoadFooterSettings
  | LoadFooterSettingsSuccess
  | LoadFooterSettingsFailed;

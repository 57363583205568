import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { AuthService } from '../_services';
import { Role } from '../_models/role';

@Injectable({ providedIn: 'root' })
export class IsAdminGuard implements CanActivate {
    constructor(private authService: AuthService) {}

    canActivate() {
        const currentUser = this.authService.currentUserValue;
        return currentUser && currentUser.role === Role.Admin;
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { combineLatest, Subscription } from 'rxjs';
import { User } from 'src/app/auth/_models';
import { Role } from 'src/app/auth/_models/role';
import { AuthService } from 'src/app/auth/_services';
import { buildMenu } from 'src/app/shared/_functions/routing.function';
import { environment } from 'src/environments/environment';
import { Permission } from './../../auth/_models/permission';
import { FeatureFlags, FeatureFlagsService } from './../../shared/_services/feature-flags.service';

@Component({
    selector: 'fp-menu',
    templateUrl: './menu.component.html',
})
export class MenuComponent implements OnDestroy, OnInit {
    currentUser: User;
    menu = new Array<MenuSection>();
    releaseDate: string;

    isFlexworker: boolean;
    isContact: boolean;
    showZendeskLink: boolean;
    subscription: Subscription;

    constructor(private router: Router, private authService: AuthService, private featureFlagsService: FeatureFlagsService) {
        this.subscription = combineLatest([
            this.authService.currentUser,
            featureFlagsService.isFeatureEnabled(FeatureFlags.useNewTimesheet),
        ]).subscribe(([user, useNewTimesheet = false]) => {
            this.currentUser = user;
            this.releaseDate = user?.role === Role.Admin ? environment.releaseDate : '';

            const role = this.currentUser?.role;
            this.isFlexworker = role === Role.Flexworker;
            this.isContact = role == Role.Contact;
            this.showZendeskLink = role === Role.Employee || role === Role.Admin;

            // Empty menu.
            this.menu = [];

            // If there is a current user, rebuilt the menu.
            if (this.currentUser) {
                this.menu = buildMenu(this.currentUser.role, this.currentUser.permissions, useNewTimesheet);
            }
        });
    }

    ngOnInit(): void {
        console.log('Menu component init');
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    toggleOpen(menuSection: MenuSection) {
        menuSection.showOpen = !menuSection.showOpen;
    }

    logout() {
        this.authService.logout();
        this.authService.navigateToLoginPage();
    }
}

export interface MenuSection {
    icon: string;
    text: string;
    visible: boolean;
    showText: boolean;
    showOpen: boolean;
    menuItems: MenuItem[];
}

export interface MenuItem {
    routerLink: string;
    text: string;
    permission?: Permission;
    visible: boolean;
}

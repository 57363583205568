import { Injectable } from '@angular/core';
import { Role } from 'src/app/auth/_models/role';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class StorageService {
    constructor() {
        window.addEventListener('storage', this.syncSessionStorageBetweenTabs, false);
        // Ask other tabs for session storage (this is ONLY to trigger event)
        if (!sessionStorage.length) {
            localStorage.setItem('getSessionStorage', 'foobar');
            localStorage.removeItem('getSessionStorage');
        }
    }

    public get cachedCurrentUser(): User {
        const userFromStorage = sessionStorage.getItem('currentUser') || localStorage.getItem('currentUser');
        return JSON.parse(userFromStorage);
    }

    public set cachedCurrentUser(user: User) {
        // Remove user from storage.
        sessionStorage.removeItem('currentUser');
        localStorage.removeItem('currentUser');

        // Set user in storage
        if (user) {
            const storage = user.role === Role.Flexworker || user.role === Role.JobSeeker ? sessionStorage : localStorage;
            storage.setItem('currentUser', JSON.stringify(user));
        }
    }

    public syncSessionStorageBetweenTabs(event): void {
        if (!event) {
            event = window.event;
        }
        if (!event.newValue) return;
        if (event.key == 'getSessionStorage') {
            // another tab asked for the sessionStorage -> send it
            localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
            // the other tab should now have it, so we're done with it.
            localStorage.removeItem('sessionStorage');
        } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
            // another tab sent data <- get it
            var data = JSON.parse(event.newValue);
            for (var key in data) {
                sessionStorage.setItem(key, data[key]);
            }
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ThemesState, getlogoUrl } from 'src/app/themes/state';

@Component({
    selector: 'error-shell',
    templateUrl: './error-shell.component.html'
})
export class ErrorShellComponent implements OnInit {
    error: string;
    translateParams: {};
    logoUrl$: Observable<string>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private themesStore: Store<ThemesState>) { }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            const { error, ...translateParams } = params;
            this.error = error;
            this.translateParams = translateParams;
            this.logoUrl$ = this.error === 'shared.messages.tenant-not-found'
                ? of(null)
                : this.themesStore.select(getlogoUrl);
            this.logoUrl$ = of(null);
        });
    }
}

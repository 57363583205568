<button *ngIf="!imageUrl" type="button" class="btn btn-primary" (click)="fileInput.click()">
    {{ 'shared.upload' | translate }}
</button>
<input accept="image/*" hidden (change)="onImageSelected($event)" #fileInput type="file" />

<div class="image-container" *ngIf="imageUrl">
    <img #image [src]="imageUrl | safeHtml"
        [ngStyle]="{'max-width.px': maxWidth || 'none', 'max-height.px': maxHeight || 'none', 'width.px': width || 'none', 'height.px': height || 'none', 'object-position': imageObjectPosition}"
        [ngClass]="{'cropped': ($isCropped | async) }" />
    <div class="overlay" (click)="fileInput.click()">
        <div class="icon">
            <i class="fas fa-edit"></i>
        </div>
    </div>
</div>

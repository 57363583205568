import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromFrame from '../../state';
import { LoadFooterSettings } from '../../state';
import { FooterSetting } from '../../_models/footer-setting';
import { AuthService } from 'src/app/auth/_services';
import { Role } from 'src/app/auth/_models/role';

@Component({
    selector: 'fp-footer-shell',
    templateUrl: 'footer-shell.component.html',
})
export class FooterShellComponent implements OnInit {
    footerSetting$: Observable<FooterSetting>;
    internetPortalName?: string | null;
    organisationName?: string | null;

    constructor(private authService: AuthService, private store: Store<fromFrame.State>) {}

    ngOnInit(): void {
        this.store.dispatch(new LoadFooterSettings('Footer Component'));

        this.footerSetting$ = this.store.pipe(select(fromFrame.getFooterSettings));

        this.authService.currentUser.subscribe((x) => {
            this.internetPortalName = x && (x.role === Role.Contact || x.role === Role.Flexworker) && x.internetPortalName;
            this.organisationName = x && x.role === Role.Contact && x.organisationName;
        });
    }
}

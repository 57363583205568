import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FrameService {
  private menuOpenSubject: BehaviorSubject<boolean>;
  public menuOpen: Observable<boolean>;

  private overlayOpenSubject: BehaviorSubject<boolean>;
  public overlayOpen: Observable<boolean>;

  private fullScreenOpenSubject: BehaviorSubject<boolean>;
  public fullScreenOpen: Observable<boolean>;

  constructor() {
    this.menuOpenSubject = new BehaviorSubject<boolean>(true);
    this.menuOpen = this.menuOpenSubject.asObservable();

    this.overlayOpenSubject = new BehaviorSubject<boolean>(false);
    this.overlayOpen = this.overlayOpenSubject.asObservable();

    this.fullScreenOpenSubject = new BehaviorSubject<boolean>(false);
    this.fullScreenOpen = this.fullScreenOpenSubject.asObservable();
  }
  toggleMenuOpen() {
    this.menuOpenSubject.next(!this.menuOpenSubject.value);
  }
  openOverlay() {
    this.overlayOpenSubject.next(true);
  }
  closeOverlay() {
    this.overlayOpenSubject.next(false);
  }
  openMenu() {
    this.menuOpenSubject.next(true);
  }
  toggleFullScreenOpen() {
    this.fullScreenOpenSubject.next(!this.fullScreenOpenSubject.value);
  }
}

<div class="period-selection">
    <span class="label">{{ 'shared.period' | translate }}</span>
    <form [formGroup]="form">
        <div class="field">
            <select formControlName="first">
                <!-- <option >{{
                    'timesheets.firstname-lastname' | translate
                }}</option> -->
                <option [value]="periodValues.Current">{{ 'shared.current' | translate }}</option>
                <option [value]="periodValues.Previous">{{ 'shared.previous' | translate }}</option>
                <option [value]="periodValues.Other">{{ 'shared.other' | translate }}</option>
            </select>
        </div>
        <div class="field">
            <select formControlName="second">
                <!-- <option >{{
                    'timesheets.firstname-lastname' | translate
                }}</option> -->
                <option value="week">{{ 'shared.week' | translate }}</option>
            </select>
        </div>
        <div class="field">
            <select formControlName="third">
                <option *ngFor="let week of weeksOptions" [value]="week">{{ week }}</option>
            </select>
        </div>
    </form>
</div>

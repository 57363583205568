import { OrganisationStatements } from './../models/organisation-statements.model';
import { groupBy, map } from 'lodash';
import { EmployeeTimesheetStatement } from './../models/employee-timesheet-statement.model';
export function groupByOrganisation(statements: EmployeeTimesheetStatement[]): OrganisationStatements[] {
    const organisations = statements.map((s) => s.organisation);
    const groupedByOrganisation = groupBy(statements, (s) => s.organisation.id);
    return map(groupedByOrganisation, (statements, key) => ({
        organisation: organisations.find((o) => o.id === +key),
        statements,
    }));
}

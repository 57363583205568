import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ConfirmLeaveComponent } from './confirm-leave.component';

export interface IDirtyFormComponent {
    isDirty(): boolean;
}

@Injectable()
export class ConfirmLeaveGuard
  implements CanDeactivate<IDirtyFormComponent> {
  public modalRef: BsModalRef;
  constructor(private modalService: BsModalService) {}

  canDeactivate(component: IDirtyFormComponent) {
    if (component.isDirty()) {
      const subject = new Subject<boolean>();

      const modal = this.modalService.show(ConfirmLeaveComponent);
      modal.content.subject = subject;

      return subject.asObservable();
    }

    return true;
  }
}

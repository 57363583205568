import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { User } from 'src/app/auth/_models';
import { AuthService } from 'src/app/auth/_services';
import { NavService } from 'src/app/frame/_services/nav.service';
import { getlogoUrl, ThemesState } from 'src/app/themes/state';
import { FrameService } from '../_services/frame.service';

@Component({
    selector: 'fp-frame',
    templateUrl: 'frame.component.html',
    styleUrls: ['frame.component.scss'],
})
export class FrameComponent implements OnInit {
    currentUser$: Observable<User>;
    menuOpen = true;
    overlayOpen = false;
    fullScreenOpen = false;
    inIFrame = false;

    logoUrl$: Observable<string>;

    hideAnotherParts: boolean;
    hideHeaderMenuAndFooter = false;

    constructor(
        private authService: AuthService,
        private frameService: FrameService,
        private navService: NavService,
        private router: Router,
        themesStore: Store<ThemesState>
    ) {
        this.inIFrame = window != window.top;

        this.onResize();

        this.currentUser$ = this.authService.currentUser;

        this.frameService.menuOpen.subscribe((x) => {
            this.menuOpen = x;
        });
        this.frameService.overlayOpen.subscribe((x) => {
            this.overlayOpen = x;
        });
        this.frameService.fullScreenOpen.subscribe((x) => {
            this.fullScreenOpen = x;
        });

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                this.navService.setBackButtonCallback(null);
            }
            if (val instanceof NavigationEnd) {
                this.frameService.closeOverlay();
                window.scrollTo(0, 0);
            }
        });

        this.logoUrl$ = themesStore.select(getlogoUrl);
    }

    ngOnInit(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((route: NavigationEnd) => {
            this.hideAnotherParts =
                window !== window.top || // The page is in an iframe
                route.url.includes('employee-facilities') ||
                route.url.includes('shifts-master-data') ||
                route.url.includes('settings/new-features') ||
                route.url.includes('requests/mdw') ||
                route.url.includes('themes/theme');
            this.hideHeaderMenuAndFooter = route.url.includes('auth/flexportalnl');

            if (this.hideAnotherParts) {
                document.documentElement.style.height = 'auto';
                document.body.style.height = 'auto';
            }
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (window.innerWidth > 992) {
            if (this.overlayOpen) {
                this.frameService.closeOverlay();
                this.frameService.openMenu();
            }
        }
    }

    closeOverlay() {
        this.frameService.closeOverlay();
    }

    getOpenClosedClasses() {
        return {
            'menu-open': this.menuOpen,
            'menu-closed': !this.menuOpen,
            'overlay-open': this.overlayOpen,
            'overlay-closed': !this.overlayOpen,
            'full-screen-open': this.fullScreenOpen,
            'full-screen-closed': !this.fullScreenOpen,
        };
    }
}

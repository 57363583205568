/**
 * Flexportal API
 *   <p>This is the documentation for the Flexportal API.</p>    <h3>Authorization</h3>  <p>Most API endpoints require authorization. Do this by adding an authorization header wiht a JWT   token in every request.</p>    <p><code>Authorization: Bearer [JWT Token goes here]</code></p>    <p>Get the JWT token through the <strong>/api/Authentication/UsernameAuthenticate</strong>  endpoint. This endpoint requires the email address/username and password of a Flexportal user.</p>    <p>Most API endpoints are specifically tied to a particular user. This is especially true for API  endpoints for flexworkers and contact persons. Some API endpoints have a more generic use. These   typically require the JWT token for an employee.</p>    <p>If you want to call employee API endpoints you may opt to create a new Flexportal user for the   specific goal of calling the API. Create the user and set the password as you would normally do.   You can initially use your own email address to set the password and change it afterward.</p>    <h3>Conventions</h3>  <p>We strive for clarity by following these conventions.</p>  <ul>      <li>No hyphens, dashes, or underscores</li>      <li>Casing: camelCase</li>      <li>flexworkerId or flexworkerNumber are more clear than Id or Number</li>  </ul>  <p>Please let us know if you spot an endpoint that does not adhere to these conventions.</p>  
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ComponentDto } from '../model/componentDto';
// @ts-ignore
import { DashboardPeriodDto } from '../model/dashboardPeriodDto';
// @ts-ignore
import { DownloadedFileDto } from '../model/downloadedFileDto';
// @ts-ignore
import { OrganisationNoteDto } from '../model/organisationNoteDto';
// @ts-ignore
import { TimesheetAccountSettingsDto } from '../model/timesheetAccountSettingsDto';
// @ts-ignore
import { TimesheetDto } from '../model/timesheetDto';
// @ts-ignore
import { TimesheetSettingsDto } from '../model/timesheetSettingsDto';
// @ts-ignore
import { UpdateTimesheetDto } from '../model/updateTimesheetDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class TimesheetsApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * BETA - Gets the settings that are bound to the user. The user is resolved from the JWT token.
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Contact and OrganisationTimesheet&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimesheetsGetTimesheetAccountSettingsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<TimesheetAccountSettingsDto>;
    public apiTimesheetsGetTimesheetAccountSettingsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<TimesheetAccountSettingsDto>>;
    public apiTimesheetsGetTimesheetAccountSettingsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<TimesheetAccountSettingsDto>>;
    public apiTimesheetsGetTimesheetAccountSettingsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<TimesheetAccountSettingsDto>(`${this.configuration.basePath}/api/timesheets/getTimesheetAccountSettings`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Download an attachment file.
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Contact and OrganisationTimesheet&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param attachmentId The id of the file to download.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimesheetsGetTimesheetAttachmentAttachmentIdGet(attachmentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<DownloadedFileDto>;
    public apiTimesheetsGetTimesheetAttachmentAttachmentIdGet(attachmentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<DownloadedFileDto>>;
    public apiTimesheetsGetTimesheetAttachmentAttachmentIdGet(attachmentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<DownloadedFileDto>>;
    public apiTimesheetsGetTimesheetAttachmentAttachmentIdGet(attachmentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (attachmentId === null || attachmentId === undefined) {
            throw new Error('Required parameter attachmentId was null or undefined when calling apiTimesheetsGetTimesheetAttachmentAttachmentIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<DownloadedFileDto>(`${this.configuration.basePath}/api/timesheets/getTimesheetAttachment/${encodeURIComponent(String(attachmentId))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Get all components for a given token.
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Contact and OrganisationTimesheet&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param componentsToken The token that is provided by the GetTimesheet end point. Note that many statements may share the same token, so cache components on the client.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimesheetsGetTimesheetComponentsComponentsTokenGet(componentsToken: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<ComponentDto>>;
    public apiTimesheetsGetTimesheetComponentsComponentsTokenGet(componentsToken: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<ComponentDto>>>;
    public apiTimesheetsGetTimesheetComponentsComponentsTokenGet(componentsToken: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<ComponentDto>>>;
    public apiTimesheetsGetTimesheetComponentsComponentsTokenGet(componentsToken: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (componentsToken === null || componentsToken === undefined) {
            throw new Error('Required parameter componentsToken was null or undefined when calling apiTimesheetsGetTimesheetComponentsComponentsTokenGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ComponentDto>>(`${this.configuration.basePath}/api/timesheets/getTimesheetComponents/${encodeURIComponent(String(componentsToken))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Gets five dashboard rows. The first row of page 1 is the current period.
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Contact and OrganisationTimesheet&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param pageNumber Page numbering starts at 1. PageNumber must be value greater than 0.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimesheetsGetTimesheetDashboardPageNumberGet(pageNumber: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<DashboardPeriodDto>>;
    public apiTimesheetsGetTimesheetDashboardPageNumberGet(pageNumber: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<DashboardPeriodDto>>>;
    public apiTimesheetsGetTimesheetDashboardPageNumberGet(pageNumber: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<DashboardPeriodDto>>>;
    public apiTimesheetsGetTimesheetDashboardPageNumberGet(pageNumber: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (pageNumber === null || pageNumber === undefined) {
            throw new Error('Required parameter pageNumber was null or undefined when calling apiTimesheetsGetTimesheetDashboardPageNumberGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<DashboardPeriodDto>>(`${this.configuration.basePath}/api/timesheets/getTimesheetDashboard/${encodeURIComponent(String(pageNumber))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Gets all data needed to display a timesheet. The organisation note is only returned if the user is impersonated by an employee.
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Contact and OrganisationTimesheet&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param periodId Identifier for a period in the format \&quot;W20201214\&quot;.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimesheetsGetTimesheetPeriodIdGet(periodId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<TimesheetDto>;
    public apiTimesheetsGetTimesheetPeriodIdGet(periodId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<TimesheetDto>>;
    public apiTimesheetsGetTimesheetPeriodIdGet(periodId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<TimesheetDto>>;
    public apiTimesheetsGetTimesheetPeriodIdGet(periodId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (periodId === null || periodId === undefined) {
            throw new Error('Required parameter periodId was null or undefined when calling apiTimesheetsGetTimesheetPeriodIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<TimesheetDto>(`${this.configuration.basePath}/api/timesheets/getTimesheet/${encodeURIComponent(String(periodId))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Gets the settings that apply to every timesheet, independent of the user or period.
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Contact and OrganisationTimesheet&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;CanApprove is True the user is not impersonated, meaning the contactperson is  logged in.               CanApprove is False if the user is impersonated and the menu setting RlAllowImpersonatorToApprove is set to false.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimesheetsGetTimesheetSettingsGet(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<TimesheetSettingsDto>;
    public apiTimesheetsGetTimesheetSettingsGet(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<TimesheetSettingsDto>>;
    public apiTimesheetsGetTimesheetSettingsGet(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<TimesheetSettingsDto>>;
    public apiTimesheetsGetTimesheetSettingsGet(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<TimesheetSettingsDto>(`${this.configuration.basePath}/api/timesheets/getTimesheetSettings`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Updates the note about the organisation. The note can only be update if the user is impersonated by an employee.               The organisation is resolved from the JWT token.
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Contact and OrganisationTimesheet&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param organisationNoteDto The changed note. Provide an empty string to delete the note.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimesheetsUpdateOrganisationNotePost(organisationNoteDto?: OrganisationNoteDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiTimesheetsUpdateOrganisationNotePost(organisationNoteDto?: OrganisationNoteDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiTimesheetsUpdateOrganisationNotePost(organisationNoteDto?: OrganisationNoteDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiTimesheetsUpdateOrganisationNotePost(organisationNoteDto?: OrganisationNoteDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/timesheets/updateOrganisationNote`,
            organisationNoteDto,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Updates the settings that are bound to the user.
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Contact and OrganisationTimesheet&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param timesheetAccountSettingsDto The changed settings.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimesheetsUpdateTimesheetAccountSettingsPost(timesheetAccountSettingsDto?: TimesheetAccountSettingsDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiTimesheetsUpdateTimesheetAccountSettingsPost(timesheetAccountSettingsDto?: TimesheetAccountSettingsDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiTimesheetsUpdateTimesheetAccountSettingsPost(timesheetAccountSettingsDto?: TimesheetAccountSettingsDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiTimesheetsUpdateTimesheetAccountSettingsPost(timesheetAccountSettingsDto?: TimesheetAccountSettingsDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/timesheets/updateTimesheetAccountSettings`,
            timesheetAccountSettingsDto,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Save the changes that the user made in the timesheet. When calling this endpoint,               include all statements in the timesheet, also the empty ones. These could be the ones that               were never filled to begin with, be also the ones that have actively been emptied by the user.
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Contact and OrganisationTimesheet&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param updateTimesheetDto Upload new attachments. Ommit deleted attachments.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiTimesheetsUpdateTimesheetPost(updateTimesheetDto?: UpdateTimesheetDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiTimesheetsUpdateTimesheetPost(updateTimesheetDto?: UpdateTimesheetDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiTimesheetsUpdateTimesheetPost(updateTimesheetDto?: UpdateTimesheetDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiTimesheetsUpdateTimesheetPost(updateTimesheetDto?: UpdateTimesheetDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/timesheets/updateTimesheet`,
            updateTimesheetDto,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

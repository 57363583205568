import { switchMap } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { EMPTY } from 'rxjs';

enum Period {
    Current = 0,
    Previous = 1,
    Other = 2,
}

@Component({
    selector: 'fp-period-selection',
    templateUrl: './period-selection.component.html',
    styleUrls: ['./period-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodSelectionComponent implements OnInit, AfterViewInit {
    form: UntypedFormGroup;

    periodValues = Period;
    @Input() weeksOptions = [];
    @Input() selectedWeek: string;
    hideWeeksSelect: boolean;

    @Output() change = new EventEmitter<string>();

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            first: this.selectedWeek ? this.periodValues.Other : this.periodValues.Previous,
            second: { value: 'week', disabled: true },
            third: this.weeksOptions[this.selectedWeek ? this.findSelectedWeek(this.selectedWeek) : 1],
        });
        this.change.emit(this.form.get('third').value);
        this.hideWeeksSelect = true;
    }
    ngAfterViewInit(): void {
        this.onFormChanges();
        this.checkIfOtherPeriod();
    }

    findSelectedWeek(week: string): number {
        return this.weeksOptions.indexOf(week);
    }

    private onFormChanges(): void {
        this.form.valueChanges
            .pipe(
                switchMap((formValue) => {
                    const firstValue = +formValue?.first;
                    const thirdValue = formValue?.third;
                    if (firstValue === this.periodValues.Current) {
                        this.change.emit(this.weeksOptions[0]);
                        this.setThirdFieldValue(this.weeksOptions[0]);
                    } else if (firstValue === this.periodValues.Previous) {
                        this.change.emit(this.weeksOptions[1]);
                        this.setThirdFieldValue(this.weeksOptions[1]);
                    } else if (firstValue === this.periodValues.Other) {
                        this.change.emit(thirdValue);
                    }
                    return EMPTY;
                })
            )
            .subscribe();
    }

    private checkIfOtherPeriod(): void {
        this.form
            .get('third')
            .valueChanges.pipe(
                switchMap((value) => {
                    if (value !== this.weeksOptions[0] && value !== this.weeksOptions[1]) {
                        this.form.get('first').setValue(Period.Other);
                    } else if (value === this.weeksOptions[0]) {
                        this.form.get('first').setValue(Period.Current);
                    } else if (value === this.weeksOptions[1]) {
                        this.form.get('first').setValue(Period.Previous);
                    }
                    return EMPTY;
                })
            )
            .subscribe();
    }

    private setThirdFieldValue(value: string): void {
        this.form.get('third').setValue(value, {
            emitEvent: false,
        });
    }
}

export enum Permission {
    // Both CP + FW
    YourInformation = 'YourInformation',
    Downloads = 'Downloads',
    Payslips = 'Payslips',

    // Only CP
    Flexworkers = 'Flexworkers',
    CostCenters = 'CostCenters',
    WorkPlanning = 'WorkPlanning',
    OrganisationTimesheet = 'OrganisationTimesheet',
    ApproveHours = 'ApproveHours',
    StatementConfirmations = 'StatementConfirmations',
    Worksheets = 'Worksheets',
    HoursRedirect = 'HoursRedirect',
    Invoices = 'Invoices',
    DownloadPayslips = 'DownloadPayslips',
    Requests = "Requests",

    // Only FW
    AbsenceAndAvailabilityList = 'AbsenceAndAvailabilityList',
    Housing = 'Housing',
    FlexworkerTimesheet = 'FlexworkerTimesheet',
    TravelStatements = 'TravelStatements',
    AnnualStatements = 'AnnualStatements',
    FlexworkerStatementsStatus = 'FlexworkerStatementsStatus',
    Reservation = 'Reservation',
    InsuranceDocuments = 'InsuranceDocuments'
}

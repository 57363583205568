import { createAction, props } from '@ngrx/store';
import { EmployeeTimesheetStatement } from './../models';
import { EmployeeTimesheetStatementHistory } from './../models/employee-timesheet-statement-history.model';

export const LoadEmployeeTimesheetStatements = createAction(
    '[Employee Facilities] Load Employee Timesheet Statements',
    props<{
        periodId: string;
        menuItemId: number;
    }>()
);

export const LoadEmployeeTimesheetStatementsSuccess = createAction(
    '[Employee Facilities] Load Employee Timesheet Statements Success',
    props<{ statements: EmployeeTimesheetStatement[] }>()
);

export const LoadEmployeeTimesheetStatementsFailure = createAction(
    '[Employee Facilities] Load Employee Timesheet Statements Failure',
    props<any>()
);

export const LoadStatementVersion = createAction(
    '[Employee Facilities] Load Statement Version',
    props<{
        statementId: number;
    }>()
);
export const LoadStatementVersionSuccess = createAction(
    '[Employee Facilities] Load Statement Version Success',
    props<{
        statementHistory: EmployeeTimesheetStatementHistory;
    }>()
);
export const LoadStatementVersionFailure = createAction(
    '[Employee Facilities] Load Statement Version Failure',
    props<any>()
);

export const SetSelectedWeek = createAction(
    '[Employee Facilities] Set Select Week',
    props<{
        selectedWeek: string;
    }>()
);

<ng-container *ngIf="!hideAnotherParts; else hide">
    <div [ngClass]="(currentUser$ | async) && !hideHeaderMenuAndFooter ? 'logged-in' : 'not-logged-in'">
        <div class="grid-container" [ngClass]="getOpenClosedClasses()">
            <div class="hl" *ngIf="(currentUser$ | async) && !hideHeaderMenuAndFooter"></div>
            <div class="hc">
                <fp-logo-header [logoUrl]="logoUrl$ | async"> </fp-logo-header>
                <!-- <fp-teams-shell></fp-teams-shell> -->
            </div>
            <div class="hr" *ngIf="(currentUser$ | async) && !hideHeaderMenuAndFooter"></div>

            <div class="sh bg-light sh-shadow" *ngIf="(currentUser$ | async) && !hideHeaderMenuAndFooter">
                <div class="bl"></div>
                <div class="cu">
                    <fp-current-user-shell></fp-current-user-shell>
                </div>
                <div class="nl">
                    <fp-nav-location></fp-nav-location>
                </div>
                <div class="br"></div>
            </div>

            <div class="me" *ngIf="(currentUser$ | async) && !hideHeaderMenuAndFooter">
                <fp-menu></fp-menu>
            </div>

            <div class="ma">
                <router-outlet></router-outlet>
            </div>

            <div class="fo">
                <fp-footer-shell></fp-footer-shell>
            </div>
        </div>

        <div class="overlay-container" [ngClass]="getOpenClosedClasses()" *ngIf="(currentUser$ | async) && !hideHeaderMenuAndFooter">
            <div class="hc2">
                <fp-logo-header [logoUrl]="logoUrl$ | async"> </fp-logo-header>
            </div>
            <div class="cu2 bg-light sh-shadow">
                <fp-current-user-shell></fp-current-user-shell>
            </div>
            <div class="me2">
                <fp-menu></fp-menu>
            </div>
            <div class="overlay" (click)="closeOverlay()"></div>
        </div>
    </div>
</ng-container>
<ng-template #hide>
    <div [ngClass]="{ 'legacy-fonts': inIFrame }">
        <router-outlet></router-outlet>
    </div>
</ng-template>

import { Component, AfterViewInit, Input } from '@angular/core';
import { ComponentUnit, ComponentValueDto } from 'src/app/timesheets/state/dtos';

@Component({
    selector: 'fp-readonly-compensation',
    templateUrl: './readonly-compensation.component.html',
    styleUrls: ['./readonly-compensation.component.scss'],
})
export class ReadonlyCompensationComponent implements AfterViewInit {
    fieldType = ComponentUnit;
    @Input() data = [] as ComponentValueDto[];

    constructor() {}

    ngAfterViewInit(): void {}

    get fieldsData(): ComponentValueDto[] {
        return this.data || [];
    }

    trackByFn(index, item) {
        return index;
    }
}

import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

export interface ISize {
    width: number;
    height: number;
}

@Injectable({
    providedIn: 'root',
})
export class FileService {
    public getImageSize(imageSrc: string): Observable<ISize> {
        const mapLoadedImage = (event): ISize => ({
            width: event.target.width,
            height: event.target.height,
        });
        const image = new Image();
        const $loadedImg = fromEvent(image, 'load').pipe(take(1), map(mapLoadedImage));
        image.src = imageSrc;
        return $loadedImg;
    }

    toBase64(file: Blob): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    dataURLtoFile(dataurl, filename): File {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    urlToBase64(url: string): Promise<string> {
        return fetch(url)
            .then((response) => response.blob())
            .then((blob) => this.toBase64(blob));
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmDeleteComponent } from './../../../shared/_components/confirm-delete/confirm-delete.component';

@Component({
    selector: 'fp-file-attachment',
    templateUrl: 'file-attachment.component.html',
    styleUrls: ['file-attachment.component.scss'],
})
export class FileAttachmentComponent implements OnInit {
    modalRef: BsModalRef;

    constructor(private modalService: BsModalService) {}

    @Input() downloadAvailable = true;
    @Input() downloading = false;
    @Input() fileName: string;
    @Output() download = new EventEmitter();
    @Output() delete = new EventEmitter();

    ngOnInit() {}

    onDeleteClick() {
        this.modalRef = this.modalService.show(ConfirmDeleteComponent);
        this.modalRef.content.onClose.subscribe((result) => {
            if (result) {
                this.delete?.emit();
            }
        });
    }

    onDownloadClick() {
        if (!this.downloading) {
            this.download?.emit();
        }
    }
}

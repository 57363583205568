import { createAction, props } from '@ngrx/store';
import { GeneralSetting } from 'src/app/auth/_models/general-setting';
import { ChangeFeatureStateOnAllTenantsDto } from 'src/app/settings/models/changeFeatureStateOnAllTenantsDto';
import { ChangeFeatureStateDto } from '../../settings/models/changeFeatureStateDto';
import { FeatureFlagChanged } from '../../settings/models/featureStateChanged';
import { GetFeatureResponse } from './../../rest/model/getFeatureResponse';
import { GetFeaturesResponse } from './../../rest/model/getFeaturesResponse';
import { GetTenantFeaturesResponse } from './../../rest/model/getTenantFeaturesResponse';
import { UpdateSettingsDto } from './../../rest/model/updateSettingsDto';

export const LoadGeneralSettings = createAction('[Shared] Load General Settings');

export const LoadGeneralSettingsSuccess = createAction('[Shared] Load General Settings Success', props<GeneralSetting>());

export const LoadGeneralSettingsFailed = createAction('[Shared] Load General Settings Failed');

export const UpdateGeneralSettings = createAction(
    '[Shared] Update General Settings',
    props<{
        updateSettings: UpdateSettingsDto;
    }>()
);

export const UpdateGeneralSettingsSuccess = createAction(
    '[Shared] Update General Settings Success',
    props<{
        updateSettings: UpdateSettingsDto;
    }>()
);

export const UpdateGeneralSettingsFailed = createAction('[Shared] Update General Settings Failed');

export const LoadAllFeatures = createAction('[Shared] Load All Features', props<{ reload: boolean }>());

export const LoadAllFeaturesSuccess = createAction('[Shared] Load All Features Success', props<GetFeaturesResponse>());

export const LoadAllFeaturesFailed = createAction('[Shared] Load All Features Failed');

export const LoadTenantFeatures = createAction('[Shared] Load Tenant Features');

export const LoadTenantFeaturesSuccess = createAction('[Shared] Load Tenant Features Success', props<GetTenantFeaturesResponse>());

export const LoadTenantFeaturesFailed = createAction('[Shared] Load Tenant Features Failed');

export const LoadCurrentFeature = createAction('[Shared] Load Current Feature', props<{ featureId: string }>());

export const LoadCurrentFeatureSuccess = createAction('[Shared] Load Current Feature Success', props<GetFeatureResponse>());

export const LoadCurrentFeatureFailed = createAction('[Shared] Load Current Feature Failed');

export const ChangeFeatureState = createAction('[Shared] Change Feature State', props<ChangeFeatureStateDto>());

export const EnableFeatureOnAllTenants = createAction(
    '[Shared] Enable Feature For Users On All Tenants',
    props<ChangeFeatureStateOnAllTenantsDto>()
);

export const EnableFeatureOnAllTenantsSuccess = createAction(
    '[Shared] Enable Feature For Users On All Tenants Success',
    props<ChangeFeatureStateOnAllTenantsDto>()
);

export const EnableFeatureOnAllTenantsFailed = createAction(
    '[Shared] Enable Feature For Users On All Tenants Failed',
    props<ChangeFeatureStateOnAllTenantsDto>()
);

export const ChangeFeatureStateSuccess = createAction('[Shared] Change Feature State Success', props<{ tenant: string }>());

export const ChangeFeatureStateFailed = createAction('[Shared] Change Feature State Failed', props<{ tenant: string }>());

export const FeatureStateChanged = createAction('[Shared] Feature State Changed', props<FeatureFlagChanged>());

export const ChangeThisTenantFeatureState = createAction('[Shared] Change This Tenant Feature State', props<ChangeFeatureStateDto>());

export const ChangeThisTenantFeatureStateSuccess = createAction(
    '[Shared] Change This Tenant Feature State Success',
    props<{ featureId: string }>()
);

export const ChangeThisTenantFeatureStateFailed = createAction(
    '[Shared] Change This Tenant Feature State Failed',
    props<{ featureId: string }>()
);

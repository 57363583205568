import { Theme } from "src/app/themes/state/themes.actions";

export const theme = <Theme>{
  logoUrl: "",
  menuItemId: null,
  favicons: [
    {
      primary: true,
    }
  ],
  headerColor: "#ffffff",
  accentColor: "#000000"
};

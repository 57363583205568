<ng-container *ngIf="isLoading$ | async; else loaded">
    <div class="loading">
        <span class="loading-text">{{ 'employee_facilities.versions_loading' | translate }}</span>
        <img src="./assets/timesheets/timesheets-placeholder.png" width="100%" />
    </div>
</ng-container>
<ng-template #loaded>
    <ng-container *ngIf="timesheetStatementHistory?.versions?.length; else noData">
        <fp-flexworker-info
            [flexworker]="timesheetStatementHistory.flexworker"
            [statement]="statement"
            [showCostCenter]="true"
            [showLargeFullName]="true"
            maxWidthPx="100%"
        ></fp-flexworker-info>
        <div class="statement-versions" *ngFor="let statementVersion of versions">
            <fp-statement-version
                [flexworker]="timesheetStatementHistory.flexworker"
                [statementVersion]="statementVersion"
            ></fp-statement-version>
        </div>
    </ng-container>
    <ng-template #noData>
        <div class="no-data">
            {{ 'employee_facilities.no_versions_data' | translate }}&nbsp;<a
                [routerLink]="['/employee-facilities', 'dashboard', menuItemId]"
            >
                <strong>{{ 'employee_facilities.click_to_go_to_dashboard' | translate }}</strong>
            </a>
        </div>
    </ng-template>
</ng-template>

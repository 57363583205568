import { ThemesState } from '.';
import { ThemesActions, ThemesActionTypes, ThemeSpecification } from './themes.actions';
import {theme} from '../../../client-assets/config';

const defaultColor = '#003388';

export const initialState: ThemesState = {
    theme,
    loadingTheme: false,
    themeSpecifications: null,
    savingThemeSpecifications: false,
    loadingThemeSpecifications: false, 
    savingFavicon: false
};

export function reducer(
    state = initialState,
    action: ThemesActions
): ThemesState {
    switch (action.type) {
        case ThemesActionTypes.Load:
            return { ...state, loadingTheme: true };
        case ThemesActionTypes.ThemeChanged:
        case ThemesActionTypes.LoadSuccess:
            return { ...state, theme: action.payload, loadingTheme: false };
        case ThemesActionTypes.Load:
            return { ...state, loadingTheme: false };

        case ThemesActionTypes.LoadSpecifications:
            return { ...state, loadingThemeSpecifications: true };
        case ThemesActionTypes.LoadSpecificationsFailed:
            return { ...state, loadingThemeSpecifications: false };
        case ThemesActionTypes.LoadSpecificationsSuccess:
            return { ...state, loadingThemeSpecifications: false, themeSpecifications: action.payload };

        case ThemesActionTypes.UpdateSpecifications:
            return { ...state, savingThemeSpecifications: true };
        case ThemesActionTypes.UpdateSpecificationsSuccess: {
            const appThemes = state.themeSpecifications.appThemes.map(t => {
                if (t.menuItemId === action.payload.menuItemId) {
                    return <ThemeSpecification>{
                        logoUrl: action.payload.logoUrl,
                        headerColor: action.payload.headerColor,
                        accentColor: action.payload.accentColor,
                        menuItemId: action.payload.menuItemId,
                        name: t.name,
                        isGeneral: t.isGeneral,
                        isSaving: false
                    };
                }

                return t;
            });

            return {
                ...state,
                themeSpecifications: { ...state.themeSpecifications, appThemes: appThemes },
                savingThemeSpecifications: false
            };
        }
        case ThemesActionTypes.UpdateSpecificationsFailed:
            return { ...state, savingThemeSpecifications: false };

        case ThemesActionTypes.UpdateFavicon:
            return { ...state, savingFavicon: true };
        case ThemesActionTypes.UpdateFaviconSuccess:
            return { ...state, theme: { ...state.theme, favicons: action.payload.favicons }, savingFavicon: false };
        case ThemesActionTypes.UpdateSpecificationsFailed:
            return { ...state, savingFavicon: false };
        case ThemesActionTypes.EditTheme: {
            const appThemes = state.themeSpecifications.appThemes.map(t => {
                if (t.menuItemId === action.payload.menuItemId) {
                    return <ThemeSpecification>{
                        ...t,
                        ...action.payload
                    };
                }

                return t;
            });

            return {
                ...state,
                themeSpecifications: { ...state.themeSpecifications, appThemes: appThemes }
            };
        }
        case ThemesActionTypes.DeleteInternetPortalTheme: {
            const appThemes = state.themeSpecifications.appThemes.map(t => {
                if (t.menuItemId === action.payload) {
                    return { ...t, headerColor: defaultColor, accentColor: defaultColor, logoUrl: null };
                }

                return t;
            });

            return { ...state, themeSpecifications: { ...state.themeSpecifications, appThemes: appThemes } };
        }
        default:
            return state;
    }
}

import moment from 'moment';

export function getWeek(firstDay, lastDay): Date[] {
    let fromDate = moment(firstDay);
    let toDate = moment(lastDay);
    let diff = toDate.diff(fromDate, 'days');
    let range = [];
    for (let i = 0; i <= diff; i++) {
        range.push(moment(firstDay).add(i, 'days').toDate());
    }
    return range;
}

import { Component, EventEmitter, Inject, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileService } from '../../../shared/_services/file.service';
import { ThemeSpecifications } from '../../state';
import { EditAppThemeDto } from '../../_models/editAppThemeDto';
import { UpdateAppFaviconDto } from '../../_models/updateAppFaviconDto';
import { UpdateAppThemeDto } from '../../_models/updateAppThemeDto';
import { BASE_PATH } from 'src/app/rest';

const colorValidator = Validators.pattern(/^#(?:[0-9a-fA-F]{3}){1,2}$/);

@Component({
    selector: 'fp-theme',
    templateUrl: 'theme.component.html',
    styleUrls: ['theme.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent {
    submitted = false;
    faviconFile: File;
    form = this.createForm();

    @Input() set theme(themeSpecifications: ThemeSpecifications) {
        const primaryIcon = themeSpecifications && themeSpecifications.favicons.find((i) => i.primary);
        const faviconUrl = primaryIcon
            ? this.basePath + "/api/appthemes/favicons/" + primaryIcon.iconHeight
            : '';

        const themes = themeSpecifications
            ? themeSpecifications.appThemes.map((t) =>
                this.fb.group({
                    logoUrl: [t.logoUrl, Validators.required],
                    name: [t.name],
                    hasTheme: [t.logoUrl !== null],
                    isGeneral: [t.menuItemId === null],
                    menuItemId: [t.menuItemId],
                    isSaving: [false],
                    headerColor: [t.headerColor, Validators.compose([Validators.required, colorValidator])],
                    accentColor: [t.accentColor, Validators.compose([Validators.required, colorValidator])],
                })
            )
            : [];
        this.form.patchValue({
            faviconUrl: faviconUrl,
        });
        this.form.setControl('themes', this.fb.array(themes));
        this.form.markAsPristine();
        this.submitted = false;
    }
    @Input() loading: boolean;
    @Input() saving: boolean;
    @Input() savingFavicon: boolean;

    @Output() update = new EventEmitter<UpdateAppThemeDto>();
    @Output() edit = new EventEmitter<EditAppThemeDto>();
    @Output() updateFavicon = new EventEmitter<UpdateAppFaviconDto>();
    @Output() delete = new EventEmitter<number>();

    createForm(): UntypedFormGroup {
        return this.fb.group({
            faviconUrl: [''],
            themes: this.fb.array([]),
        });
    }

    constructor(
        private fb: UntypedFormBuilder,
        private imageService: FileService,
        @Inject(BASE_PATH) private basePath) { }

    get f(): any {
        return this.form.controls;
    }

    get themesArray() {
        return this.form.get('themes') as UntypedFormArray;
    }

    onFaviconChange(imageFile) {
        this.faviconFile = imageFile;
    }

    isDirty(): boolean {
        return this.form.dirty;
    }

    onEditTheme(index: number) {
        const internetPortalTheme = <EditAppThemeDto>this.themesArray.controls[index].value;

        this.edit.emit(internetPortalTheme);
    }

    async onAddTheme(index: number) {
        const imageData = await this.imageService.urlToBase64(this.themesArray.controls[0].value.logoUrl);
        const internetPortalTheme = <EditAppThemeDto>{
            ...this.themesArray.controls[0].value,
            logoUrl: imageData,
            menuItemId: this.themesArray.controls[index].get('menuItemId').value,
            name: this.themesArray.controls[index].get('name').value,
        };

        this.edit.emit(internetPortalTheme);
    }

    onSaveTheme(index: number) {
        this.themesArray.controls[index].get('isSaving').setValue(true);
        const updatedTheme = <UpdateAppThemeDto>this.themesArray.controls[index].value;
        const logoUrl = this.themesArray.controls[index].get('logoUrl').value;
        if (logoUrl && logoUrl.startsWith('data:')) {
            updatedTheme.logo = this.imageService.dataURLtoFile(this.themesArray.controls[index].get('logoUrl').value, 'logo');
        }
        this.update.emit(updatedTheme);
        this.themesArray.controls[index].markAsPristine();
    }

    onDeleteTheme(index: number) {
        this.delete.emit(this.themesArray.controls[index].get('menuItemId').value);
    }

    onSaveFavicon() {
        const updatedFavicon = <UpdateAppFaviconDto>{
            favicon: this.faviconFile,
        };
        this.updateFavicon.emit(updatedFavicon);
        this.form.get('faviconUrl').markAsPristine();
    }
}

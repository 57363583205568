import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class AppInsightsMonitoringService {
    appInsights: ApplicationInsights;

    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.appInsights.instrumentationKey
            }
        });

        if (environment.production) {
            this.appInsights.loadAppInsights();
            this.loadCustomTelemetryProperties();
        }
    }

    setUserId(userId: string) {
        if (environment.production) {
            this.appInsights.setAuthenticatedUserContext(userId);
        }
    }

    clearUserId() {
        if (environment.production) {
            this.appInsights.clearAuthenticatedUserContext();
        }
    }

    logPageView(name?: string, url?: string) {
        if (environment.production) {
            this.appInsights.trackPageView({
                name: name,
                uri: url
            });
        }
    }

    logException(exception: Error, severityLevel?: number) {
        if (environment.production) {
            this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
        }
    }

    private loadCustomTelemetryProperties() {
        this.appInsights.addTelemetryInitializer(envelope => {
            const item = envelope.baseData;
            item.properties = item.properties || {};
            item.properties['ApplicationPlatform'] = 'WEB';
            item.properties['ApplicationName'] = 'Flexportal UI';
        });
    }
}

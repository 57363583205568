import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    AuthenticateResponse,
    AuthenticationApiService,
    BASE_PATH,
    RefreshJwtTokenDto,
    TokenAuthenticateDto,
    UsernameAuthenticateDto,
} from 'src/app/rest';
import { AppInsightsMonitoringService } from 'src/app/shared/_services/monitoring.service';
import { User } from '../_models';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private currentUserSubject: BehaviorSubject<User>;
    private jwtHelper = new JwtHelperService();
    private logoutJobId: any;
    public currentUser: Observable<User>;

    constructor(
        private authenticationApiService: AuthenticationApiService,
        private monitoringService: AppInsightsMonitoringService,
        private storage: StorageService,
        private router: Router,
        @Inject(BASE_PATH) private basePath
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(this.storage.cachedCurrentUser);
        this.currentUser = this.currentUserSubject.asObservable();

        if (this.currentUserSubject.value) {
            this.scheduleLogoutOnTokenExpire(this.currentUserSubject.value.jwtToken);
            this.monitoringService.setUserId(this.currentUserSubject.value.id.toString());
        }
    }

    public get currentUserValue(): User {
        const value = this.currentUserSubject.value;
        if (value === null) {
            return null;
        }

        if (value.jwtToken && this.jwtHelper.isTokenExpired(value.jwtToken)) {
            this.logout();
            return null;
        }

        return value;
    }

    login(credentials: UsernameAuthenticateDto): Observable<AuthenticateResponse> {
        return this.authenticationApiService.apiAuthenticationUsernameAuthenticatePost(credentials).pipe(
            map((response) => {
                this.handleAuthResponse(response);

                return response;
            })
        );
    }

    loginByToken(token: string): Observable<AuthenticateResponse> {
        if (this.currentUserValue) {
            this.logout();
        }

        const tokenAuthenticateDto = <TokenAuthenticateDto>{ token };
        return this.authenticationApiService.apiAuthenticationTokenAuthenticatePost(tokenAuthenticateDto).pipe(
            map((response) => {
                this.handleAuthResponse(response);
                return response;
            })
        );
    }

    refreshJwtToken(): Observable<AuthenticateResponse> {
        const dto = <RefreshJwtTokenDto>{ refreshToken: this.currentUserValue.refreshToken };
        return this.authenticationApiService.apiAuthenticationRefreshJwtTokenPost(dto).pipe(
            map((response) => {
                this.handleAuthResponse(response);
                return response;
            })
        );
    }

    logout() {
        this.storage.cachedCurrentUser = null;
        this.currentUserSubject.next(null);
        this.monitoringService.clearUserId();
    }
    navigateToLoginPage() {
        this.router.navigate(['/auth/login']);
    }

    getFlexportalNLUrl(): string {
        // HACK ALERT - Florian Hoornaar - 2021-08-11:
        //  The original code removes all numbers from the URL. I do not know why this happens.
        //  I can imagine this is done to remove the port number. Unfortunately it also removes
        //  the number '1' from 'level1', resulting in an incorrect URL. For the moment, a hacked
        //  solution is easy. So this is what it is. For future development, feel free to improve.
        if (this.basePath.toLocaleLowerCase() === 'https://level1.flexportal.com') return 'https://level1.flexportal.nl';
        if (this.basePath.toLocaleLowerCase() === 'https://network31.flexportal.com') return 'https://network31.flexportal.nl';
        if (this.basePath.toLocaleLowerCase() === 'https://pay2day.flexportal.com') return 'https://pay2day.flexportal.nl';
        if (this.basePath.toLocaleLowerCase() === 'https://want2work.flexportal.com') return 'https://want2work.flexportal.nl';

        const nlLocation = this.basePath.replace(/[0-9]/g, '').replace(/\:$/, '').replace('.com', '.nl');
        return nlLocation;
    }

    getFlexportalEmbeddedNlUrl(): string {
        let nlUrl = this.getFlexportalNLUrl();
        let embeddedUrl = nlUrl.replace('.flexportal.nl', '-embedded.flexportal.com');
        return embeddedUrl;
    }

    private handleAuthResponse(response: AuthenticateResponse) {
        // login successful if there's a jwt token in the response
        if (response && response.jwtToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const user = <User>response;

            this.scheduleLogoutOnTokenExpire(response.jwtToken);
            this.storage.cachedCurrentUser = user;
            this.monitoringService.setUserId(user.id.toString());
            this.currentUserSubject.next(user);
        }
    }

    private scheduleLogoutOnTokenExpire(jwtToken: string): void {
        if (this.logoutJobId) {
            clearTimeout(this.logoutJobId);
        }
        const tokenExpiresAt = this.jwtHelper.getTokenExpirationDate(jwtToken);
        const msTillExpiration = tokenExpiresAt.getTime() - Date.now();
        if (msTillExpiration > 0) {
            this.logoutJobId = setTimeout(() => {
                this.logout();
                this.navigateToLoginPage();
            }, msTillExpiration);
        }
    }
}

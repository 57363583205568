<footer class="text-muted mx-1">
    <div class="company">
        <i class="fal fa-building mr-1"></i>
        <span>{{ name }}</span>
        <span *ngIf="internetPortalName"> / {{ internetPortalName }}</span>
        <span *ngIf="organisationName"> / {{ organisationName }}</span>
    </div>
    <div class="phone">
        <i class="ml-3 fal fa-phone mr-1"></i>
        <span>{{ telephone }}</span>
    </div>
    <div class="email">
        <i class="ml-3 fal fa-envelope mr-1"></i>
        <a [href]="'mailto:' + email">{{ email }}</a>
    </div>

    <div class="small-footer">
        <a [href]="'tel:' + telephone">
            <i class="fal fa-phone mr-1 fa-lg"></i>
        </a>
        <a [href]="'mailto:' + email">
            <i class="fal fa-envelope mr-1 fa-lg"></i>
        </a>
    </div>
</footer>

<div class="comment row" [class.readonly-comment]="readonly">
    <label class="field-label">{{ 'shared.comment' | translate }}</label>
    <ng-container *ngIf="readonly; else default">
        <span class="comment-value">{{ comment }}</span>
    </ng-container>
    <ng-template #default>
        <form [formGroup]="form">
            <textarea
                autogrow
                rows="2"
                maxlength="1260"
                class="form-control"
                formControlName="comment"
            ></textarea>
            <span
                *ngIf="1260 - commentValue?.split('').length <= 180"
                class="comment-length"
                [class.warning-length]="1260 - commentValue?.split('').length <= 40"
                >{{ 1260 - commentValue?.split('').length || 0 }} left</span
            >
        </form>
    </ng-template>
</div>

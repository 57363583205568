import { first, take } from 'rxjs/operators';
import { SetSelectedWeek } from './../../state/employee-facilities-dashboard.actions';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { OrganisationStatements } from '../../models/organisation-statements.model';
import * as fromStore from '../../state';
import { EmployeeFacilitiesDashboardState } from '../../state';
import { LoadEmployeeTimesheetStatements } from '../../state/employee-facilities-dashboard.actions';

@Component({
    selector: 'fp-timesheet-dashboard-shell',
    templateUrl: './timesheet-dashboard-shell.component.html',
    styleUrls: ['./timesheet-dashboard-shell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetDashboardShellComponent implements OnInit {
    organisationStatements$: Observable<OrganisationStatements[]>;

    selectedWeek: string;
    skipWeekSelection = true;

    isLoading$: Observable<boolean>;

    weeksOptions = [] as string[];
    menuItemId: number;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<EmployeeFacilitiesDashboardState>
    ) {}

    ngOnInit(): void {
        this.menuItemId = this.route.snapshot.params['menuItemId'];
        this.isLoading$ = this.store.select(fromStore.getLoading);
        this.organisationStatements$ = this.store.select(fromStore.getOrganisationStatements);
        this.store
            .select(fromStore.getSelectedWeek)
            .pipe(take(1))
            .subscribe((value) => (this.selectedWeek = value));
        this.weeksOptions = this.createWeekOptions();
    }

    navigateToVersions(statementId: string): void {
        this.router.navigate(['employee-facilities', 'versions', this.menuItemId, statementId]);
    }

    loadTimesheetStatements(periodId: string): void {
        this.store.dispatch(
            LoadEmployeeTimesheetStatements({
                periodId,
                menuItemId: this.menuItemId,
            })
        );
    }

    changePeriod(weekOfYear: string): void {
        if (typeof weekOfYear !== 'string') {
            return;
        }

        if (!this.skipWeekSelection) {
            this.store.dispatch(SetSelectedWeek({ selectedWeek: weekOfYear }));
        }
        this.skipWeekSelection = false;
        let periodId;
        if (weekOfYear[1]) {
            periodId = `W${weekOfYear.split('-').join('')}`;
        }
        this.loadTimesheetStatements(periodId);
    }

    private createWeekOptions(): string[] {
        let weeks = [];
        let endDate = moment(new Date()).isoWeekday(4);
        if (endDate.date() === 8) {
            endDate = endDate.isoWeekday(-6);
        }
        while (weeks.length < 55) {
            const weekNumber = endDate.isoWeek();
            endDate.add(-7, 'days');
            const startDateWeek = endDate.isoWeekday('Monday').format('YYYY');
            weeks.push({ startDateWeek, weekNumber: weekNumber });
        }

        return weeks.map((w) => w.startDateWeek + '-' + this.checkWeekNumber(w.weekNumber));
    }

    private checkWeekNumber(weekNumber: number) {
        return weekNumber / 10 >= 1 ? +weekNumber : `0${weekNumber}`;
    }
}

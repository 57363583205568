export interface TimesheetDtoInterface {
    periodId?: string | null;
    periodName?: PeriodName;
    periodNumber?: number;
    yearNumber?: number;
    firstDay?: string;
    lastDay?: string;
    organisations?: Array<OrganisationDto> | null;
    flexworkers?: Array<TimesheetFlexworkerDto> | null;
    statements?: Array<TimesheetStatementDto> | null;
}

export type PeriodName = 0;

export const PeriodName = {
    NUMBER_0: 0 as PeriodName,
};

export interface CostCenterDto {
    id?: number;
    name?: string | null;
}

export interface OrganisationDto {
    id?: number;
    name?: string | null;
    note?: string | null;
    timesheetMode?: TimesheetMode;
    costCenterRequired?: boolean;
    autoShowComponents?: boolean;
    costCenters?: Array<CostCenterDto> | null;
    shifts?: Array<ShiftDto>;
}

export interface ShiftDto {
    id?: number;
    name?: string;
}

export type TimesheetGender = 0 | 1 | 2;

export const TimesheetGender = {
    NUMBER_0: 0 as TimesheetGender,
    NUMBER_1: 1 as TimesheetGender,
    NUMBER_2: 2 as TimesheetGender,
};

export interface TimesheetFlexworkerDto {
    id?: number;
    fullName?: string | null;
    fullNameReversed?: string | null;
    gender?: TimesheetGender;
    /**
     * Two character country code
     */
    nationality?: string | null;
}

export class TimesheetStatementDto {
    periodId?: string | null;
    firstDay?: string;
    lastDay?: string;
    status?: TimesheetStatementStatus;
    organisationId?: number;
    flexworkerId?: number;
    jobTitle?: string | null;
    costCenterId?: number | null;
    componentsToken?: string | null;
    hours?: HourDto[] | null;
    componentValues?: Array<ComponentValueDto> | null;
    attachments?: Array<AttachmentDto> | null;
    files?: Array<UploadedFileDto> | null;
    comment?: string | null;
}

export enum TimesheetStatementStatus {
    Empty = 0,
    Planning,
    TimeRegistration,
    FlexworkerSaved,
    FlexworkerApproved,
    OrganisationSaved,
    OrganisationApproved,
}

export interface HourDto {
    date?: unknown | string;
    startTime?: number | null;
    endTime?: number | null;
    breakTime?: number | null;
    duration?: number;
}

export interface AttachmentDto {
    id?: number;
    fileName?: string | null;
}

export interface UploadedFileDto {
    fileName?: string | null;
    base64Content?: string | null;
    contentType?: string | null;
}

export interface ComponentValueDto {
    componentDto?: ComponentDto;
    value?: number;
}

export interface ComponentDto {
    flexworkerName?: string | null;
    organisationName?: string | null;
    componentUnit?: ComponentUnit;
    flexworkerMultiplier?: number;
    organisationMultiplier?: number;
}

export enum ComponentUnit {
    Hour,
    Day,
    WorkedDay,
    Piece,
    Kilometer,
    Amount,
}

export enum ComponentType {
    Hours,
    Compensations,
    Reservations,
}

export enum TimesheetMode {
    DayTotal,
    StartEndBreakTime,
    MultiDayTrips,
}

<div class="version">
    <div class="version-save-info">
        <span
            class="version-save-info-flag"
            [class.flag-approved]="statementStatus.OrganisationApproved === statementVersion.version.status"
            [class.flag-saved]="statementStatus.OrganisationSaved === statementVersion.version.status"
            >&nbsp;&nbsp;&nbsp;&nbsp;</span
        >
        <ng-container *ngIf="statementStatus.OrganisationApproved === statementVersion.version.status">
            {{ 'timesheets.approved_by_contact_person' | translate }}
        </ng-container>
        <ng-container *ngIf="statementStatus.OrganisationSaved === statementVersion.version.status">
            {{ 'timesheets.saved_by_contact_person' | translate }}
        </ng-container>
        <strong>{{ statementVersion.version.changedBy }}</strong> {{ 'shared.on' | translate
        }}<strong> {{ statementVersion.version.changedOn | date: 'EEEE d LLLL' }} </strong
        >{{ 'shared.at' | translate | lowercase }}
        <strong>{{ statementVersion.version.changedOn | date: 'H:mm' }}</strong>
        <span class="difference" *ngIf="!!statementVersion.difference">{{ statementVersion.difference }}</span>
    </div>
    <div class="version-hours-row" *ngIf="statementVersion.version.hours?.length">
        <fp-readonly-hours-row
            [timesheetMode]="statementVersion.version.timesheetMode"
            [hours]="statementVersion.version.hours"
        ></fp-readonly-hours-row>
    </div>
    <div class="version-compensation" *ngIf="statementVersion.version.componentValues?.length">
        <fp-readonly-compensation [data]="statementVersion.version.componentValues"></fp-readonly-compensation>
    </div>
    <div class="version-comment" *ngIf="statementVersion.version.comment">
        <fp-timesheet-comment [comment]="'Hello World here is comment'" [readonly]="true"></fp-timesheet-comment>
    </div>
    <div class="version-attachments" *ngIf="statementVersion.version.attachments?.length">
        <fp-timesheet-attachments
            [attachments]="statementVersion.version.attachments"
            [readonly]="true"
        ></fp-timesheet-attachments>
    </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class NavService {
    private currentLocationSubject: BehaviorSubject<ILocationHeader>;
    public currentLocation: Observable<ILocationHeader>;
    private backButtonCallbackSubject: BehaviorSubject<Function>;
    public backButtonCallback: Observable<Function>;

    constructor() {
        this.currentLocationSubject = new BehaviorSubject<ILocationHeader>({} as ILocationHeader);
        this.currentLocation = this.currentLocationSubject.asObservable();
        this.backButtonCallbackSubject = new BehaviorSubject<Function>(null);
        this.backButtonCallback = this.backButtonCallbackSubject.asObservable();
    }

    setLocation(translationKey: string, params: any = null): void {
        this.currentLocationSubject.next({
            translationKey: translationKey,
            params: params,
        } as ILocationHeader);
    }

    setBackButtonCallback(callback: Function): void {
        this.backButtonCallbackSubject.next(callback);
    }
}

export interface ILocationHeader {
    translationKey: string;
    params: any;
}

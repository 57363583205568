/**
 * Flexportal API
 *   <p>This is the documentation for the Flexportal API.</p>    <h3>Authorization</h3>  <p>Most API endpoints require authorization. Do this by adding an authorization header wiht a JWT   token in every request.</p>    <p><code>Authorization: Bearer [JWT Token goes here]</code></p>    <p>Get the JWT token through the <strong>/api/Authentication/UsernameAuthenticate</strong>  endpoint. This endpoint requires the email address/username and password of a Flexportal user.</p>    <p>Most API endpoints are specifically tied to a particular user. This is especially true for API  endpoints for flexworkers and contact persons. Some API endpoints have a more generic use. These   typically require the JWT token for an employee.</p>    <p>If you want to call employee API endpoints you may opt to create a new Flexportal user for the   specific goal of calling the API. Create the user and set the password as you would normally do.   You can initially use your own email address to set the password and change it afterward.</p>    <h3>Conventions</h3>  <p>We strive for clarity by following these conventions.</p>  <ul>      <li>No hyphens, dashes, or underscores</li>      <li>Casing: camelCase</li>      <li>flexworkerId or flexworkerNumber are more clear than Id or Number</li>  </ul>  <p>Please let us know if you spot an endpoint that does not adhere to these conventions.</p>  
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { DownloadDto } from '../model/downloadDto';
// @ts-ignore
import { DownloadedFile } from '../model/downloadedFile';
// @ts-ignore
import { GetOrganisationResponse } from '../model/getOrganisationResponse';
// @ts-ignore
import { InvoiceDto } from '../model/invoiceDto';
// @ts-ignore
import { OrganisationInfo } from '../model/organisationInfo';
// @ts-ignore
import { PostOrganisationDto } from '../model/postOrganisationDto';
// @ts-ignore
import { PostOrganisationResponseDto } from '../model/postOrganisationResponseDto';
// @ts-ignore
import { PutOrganisationDto } from '../model/putOrganisationDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class OrganisationsApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * BETA - Returns a list of organisations.
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Admin, ApiAccess, Employee&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param internetPortalId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOrganisationsGet(internetPortalId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<OrganisationInfo>>;
    public apiOrganisationsGet(internetPortalId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<OrganisationInfo>>>;
    public apiOrganisationsGet(internetPortalId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<OrganisationInfo>>>;
    public apiOrganisationsGet(internetPortalId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (internetPortalId !== undefined && internetPortalId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>internetPortalId, 'internetPortalId');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<OrganisationInfo>>(`${this.configuration.basePath}/api/organisations`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Returns a list of documents that are relevant for the organisation.
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Contact and Downloads&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param organisationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOrganisationsOrganisationIdDownloadsGet(organisationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<DownloadDto>>;
    public apiOrganisationsOrganisationIdDownloadsGet(organisationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<DownloadDto>>>;
    public apiOrganisationsOrganisationIdDownloadsGet(organisationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<DownloadDto>>>;
    public apiOrganisationsOrganisationIdDownloadsGet(organisationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling apiOrganisationsOrganisationIdDownloadsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<DownloadDto>>(`${this.configuration.basePath}/api/organisations/${encodeURIComponent(String(organisationId))}/downloads`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Admin, ApiAccess, Employee, Contact&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param organisationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOrganisationsOrganisationIdGet(organisationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<GetOrganisationResponse>;
    public apiOrganisationsOrganisationIdGet(organisationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<GetOrganisationResponse>>;
    public apiOrganisationsOrganisationIdGet(organisationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<GetOrganisationResponse>>;
    public apiOrganisationsOrganisationIdGet(organisationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling apiOrganisationsOrganisationIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<GetOrganisationResponse>(`${this.configuration.basePath}/api/organisations/${encodeURIComponent(String(organisationId))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Contact and Invoices&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param organisationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOrganisationsOrganisationIdInvoicesGet(organisationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<InvoiceDto>>;
    public apiOrganisationsOrganisationIdInvoicesGet(organisationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<InvoiceDto>>>;
    public apiOrganisationsOrganisationIdInvoicesGet(organisationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<InvoiceDto>>>;
    public apiOrganisationsOrganisationIdInvoicesGet(organisationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling apiOrganisationsOrganisationIdInvoicesGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<InvoiceDto>>(`${this.configuration.basePath}/api/organisations/${encodeURIComponent(String(organisationId))}/invoices`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Contact and Invoices&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param organisationId 
     * @param invoiceIdNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOrganisationsOrganisationIdInvoicesInvoiceIdNumberGet(organisationId: number, invoiceIdNumber: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<DownloadedFile>;
    public apiOrganisationsOrganisationIdInvoicesInvoiceIdNumberGet(organisationId: number, invoiceIdNumber: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<DownloadedFile>>;
    public apiOrganisationsOrganisationIdInvoicesInvoiceIdNumberGet(organisationId: number, invoiceIdNumber: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<DownloadedFile>>;
    public apiOrganisationsOrganisationIdInvoicesInvoiceIdNumberGet(organisationId: number, invoiceIdNumber: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling apiOrganisationsOrganisationIdInvoicesInvoiceIdNumberGet.');
        }
        if (invoiceIdNumber === null || invoiceIdNumber === undefined) {
            throw new Error('Required parameter invoiceIdNumber was null or undefined when calling apiOrganisationsOrganisationIdInvoicesInvoiceIdNumberGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<DownloadedFile>(`${this.configuration.basePath}/api/organisations/${encodeURIComponent(String(organisationId))}/invoices/${encodeURIComponent(String(invoiceIdNumber))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Updates an existing organisation
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Admin, ApiAccess, Employee&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;&lt;h3&gt;Fields&lt;/h3&gt;  &lt;table&gt;    &lt;thead&gt;      &lt;tr&gt;        &lt;td&gt;Field&lt;/td&gt;        &lt;td&gt;Type&lt;/td&gt;        &lt;td&gt;Required&lt;/td&gt;        &lt;td&gt;Validation rules / description&lt;/td&gt;      &lt;/tr&gt;    &lt;/thead&gt;    &lt;tbody&gt;      &lt;tr&gt;        &lt;td&gt;RegistrationNumber&lt;/td&gt;        &lt;td&gt;string(256)&lt;/td&gt;        &lt;td&gt;No&lt;/td&gt;        &lt;td&gt;&lt;/td&gt;      &lt;/tr&gt;      &lt;tr&gt;        &lt;td&gt;Name&lt;/td&gt;        &lt;td&gt;string(100)&lt;/td&gt;        &lt;td&gt;Yes&lt;/td&gt;        &lt;td&gt;&lt;/td&gt;      &lt;/tr&gt;    &lt;/tbody&gt;  &lt;/table&gt;
     * @param organisationId Must be greater than 0. This is the id of the organisation in the flexportal.
     * @param putOrganisationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOrganisationsOrganisationIdPut(organisationId: number, putOrganisationDto?: PutOrganisationDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiOrganisationsOrganisationIdPut(organisationId: number, putOrganisationDto?: PutOrganisationDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiOrganisationsOrganisationIdPut(organisationId: number, putOrganisationDto?: PutOrganisationDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiOrganisationsOrganisationIdPut(organisationId: number, putOrganisationDto?: PutOrganisationDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling apiOrganisationsOrganisationIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/organisations/${encodeURIComponent(String(organisationId))}`,
            putOrganisationDto,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Creates an organisation
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Admin, ApiAccess, Employee&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;&lt;h3&gt;Fields&lt;/h3&gt;  &lt;table&gt;    &lt;thead&gt;      &lt;tr&gt;        &lt;td&gt;Field&lt;/td&gt;        &lt;td&gt;Type&lt;/td&gt;        &lt;td&gt;Required&lt;/td&gt;        &lt;td&gt;Validation rules / description&lt;/td&gt;      &lt;/tr&gt;    &lt;/thead&gt;    &lt;tbody&gt;      &lt;tr&gt;        &lt;td&gt;RegistrationNumber&lt;/td&gt;        &lt;td&gt;string(256)&lt;/td&gt;        &lt;td&gt;No&lt;/td&gt;        &lt;td&gt;&lt;/td&gt;      &lt;/tr&gt;      &lt;tr&gt;        &lt;td&gt;Name&lt;/td&gt;        &lt;td&gt;string(100)&lt;/td&gt;        &lt;td&gt;Yes&lt;/td&gt;        &lt;td&gt;&lt;/td&gt;      &lt;/tr&gt;    &lt;/tbody&gt;  &lt;/table&gt;
     * @param postOrganisationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiOrganisationsPost(postOrganisationDto?: PostOrganisationDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<PostOrganisationResponseDto>;
    public apiOrganisationsPost(postOrganisationDto?: PostOrganisationDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<PostOrganisationResponseDto>>;
    public apiOrganisationsPost(postOrganisationDto?: PostOrganisationDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<PostOrganisationResponseDto>>;
    public apiOrganisationsPost(postOrganisationDto?: PostOrganisationDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<PostOrganisationResponseDto>(`${this.configuration.basePath}/api/organisations`,
            postOrganisationDto,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

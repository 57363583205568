import { Component, HostListener } from '@angular/core';
import { FrameService } from 'src/app/frame/_services/frame.service';
import { NavService, ILocationHeader } from '../_services/nav.service';

@Component({
  selector: 'fp-nav-location',
  templateUrl: './nav-location.component.html',
  styleUrls: ['./nav-location.component.scss']
})
export class NavLocationComponent {
  locationHeader: ILocationHeader;
  backButtonCallback: Function;
  screenWidth: any;

  constructor(
    private navService: NavService,
    private frameService: FrameService
  ) {
    this.onResize();
    this.navService.currentLocation.subscribe(x => {
      this.locationHeader = x;
    });
    this.navService.backButtonCallback.subscribe(x => {
      this.backButtonCallback = x;
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
  toggleMenuOpen() {
    if (this.screenWidth < 992) {
      this.frameService.openOverlay();
    } else {
      this.frameService.toggleMenuOpen();
    }
  }
  toggleFullScreenOpen() {
    this.frameService.toggleFullScreenOpen();
  }
}

import { EmployeeTimesheetStatementVersionDifference } from './../../models/employee-timesheet-statement-version-difference.model';
import { EmployeeTimesheetFlexworker } from './../../models/employee-timesheet-flexworker.model';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TimesheetStatementStatus } from 'src/app/timesheets/state/dtos';

@Component({
    selector: 'fp-statement-version',
    templateUrl: './statement-version.component.html',
    styleUrls: ['./statement-version.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementVersionComponent implements OnInit {
    @Input() statementVersion = {} as EmployeeTimesheetStatementVersionDifference;
    @Input() flexworker = {} as EmployeeTimesheetFlexworker;
    statementStatus = TimesheetStatementStatus;

    constructor() {}

    ngOnInit(): void {}
}

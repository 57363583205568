import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { LoadTenantFeatures } from './shared/state';
import { SignalRService } from './shared/_services/signalr.service';
import * as fromRoot from './state';
import { Load } from './themes/state';

@Component({
    selector: 'fp-app',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
    constructor(
        private signalrService: SignalRService,
        private store: Store<any>,
        private sanitizer: DomSanitizer,
        @Inject(DOCUMENT) private document: Document
    ) {
        // Initialize the language to use for translations.
        this.store.dispatch(new fromRoot.Init());
        this.store.dispatch(new fromRoot.LoadPageMenuTabs());
        this.store.dispatch(new Load('App Component'));
        this.store.dispatch(LoadTenantFeatures());
    }

    ngOnInit(): void {
        this.signalrService.init();
    }
}

import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/_services';
import { Role } from '../auth/_models/role';

@Component({
    selector: 'fp-no-access',
    templateUrl: './no-access.component.html',
    styleUrls: ['./no-access.component.scss'],
})
export class NoAccessComponent implements OnInit {
    message: string;

    constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService) {}

    ngOnInit(): void {
        if (this.authService.currentUserValue?.role === Role.Contact) {
            this.router.navigate(['/organisation-portal/choose-your-portal']);
        } else {
            this.message = this.route.snapshot.queryParams['access'] || 'no_access';
        }
    }
}

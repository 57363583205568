import { Injectable } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators';
import { Role } from 'src/app/auth/_models/role';
import { AuthService } from 'src/app/auth/_services';
import * as fromShared from '.';
import { GeneralSetting } from '../../auth/_models/general-setting';
import { GeneralSettingsApiService } from '../../rest/api/generalSettingsApi.service';
import { FeatureFlagsService } from '../_services/feature-flags.service';
import { FeatureFlagsApiService } from './../../rest/api/featureFlagsApi.service';
import { AllTenantsFeatureStateDto } from './../../rest/model/allTenantsFeatureStateDTO';
import { FeatureStateDto } from './../../rest/model/featureStateDTO';
import { ChangeFeatureStateOnAllTenantsDto } from './../../settings/models/changeFeatureStateOnAllTenantsDto';

@Injectable()
export class SharedEffects {
    constructor(
        private actions$: Actions,
        private generalSettingsApiService: GeneralSettingsApiService,
        private featureFlagsApiService: FeatureFlagsApiService,
        private featureFlagsService: FeatureFlagsService,
        private authService: AuthService,
        private toastr: ToastrService,
        private translateService: TranslateService
    ) { }

    loadGeneralSettings = createEffect(() =>
        this.actions$.pipe(
            ofType(fromShared.LoadGeneralSettings),
            mergeMap(() =>
                this.generalSettingsApiService.apiGeneralSettingsGetAllGet().pipe(
                    map((data) => fromShared.LoadGeneralSettingsSuccess(data as GeneralSetting)),
                    catchError(() => of(fromShared.LoadGeneralSettingsFailed()))
                )
            )
        )
    );

    updateGeneralSettings = createEffect(() =>
        this.actions$.pipe(
            ofType(fromShared.UpdateGeneralSettings),
            exhaustMap((action) =>
                this.generalSettingsApiService.apiGeneralSettingsUpdateSettingsPost(action.updateSettings).pipe(
                    map(() => {
                        this.translateService
                            .get(<string>_('shared.messages.the-changes-are-saved'))
                            .subscribe((text) => this.toastr.success(text));
                        const updateSettings = action.updateSettings;
                        return fromShared.UpdateGeneralSettingsSuccess({ updateSettings });
                    }),
                    catchError(() => of(fromShared.UpdateGeneralSettingsFailed()))
                )
            )
        )
    );

    loadAllFeatures = createEffect(() =>
        this.actions$.pipe(
            ofType(fromShared.LoadAllFeatures),
            mergeMap(() =>
                this.featureFlagsApiService.apiFeatureFlagsGetFeaturesGet().pipe(
                    map((data) => fromShared.LoadAllFeaturesSuccess(data)),
                    catchError(() => of(fromShared.LoadAllFeaturesFailed()))
                )
            )
        )
    );

    loadTenantFeatures = createEffect(() =>
        this.actions$.pipe(
            ofType(fromShared.LoadTenantFeatures),
            mergeMap(() =>
                this.featureFlagsApiService.apiFeatureFlagsGetTenantFeaturesGet().pipe(
                    map((data) => fromShared.LoadTenantFeaturesSuccess(data)),
                    catchError(() => of(fromShared.LoadTenantFeaturesFailed()))
                )
            )
        )
    );

    loadCurrentFeature = createEffect(() =>
        this.actions$.pipe(
            ofType(fromShared.LoadCurrentFeature),
            mergeMap(({ featureId }) =>
                this.featureFlagsApiService.apiFeatureFlagsGetFeatureFeatureIdGet(featureId).pipe(
                    map((data) => fromShared.LoadCurrentFeatureSuccess(data)),
                    catchError(() => of(fromShared.LoadCurrentFeatureFailed()))
                )
            )
        )
    );

    changeFeatureState = createEffect(() =>
        this.actions$.pipe(
            ofType(fromShared.ChangeFeatureState),
            mergeMap(({ featureId, isEnabled, isEnabledForAdmins, tenant }) =>
                this.featureFlagsApiService
                    .apiFeatureFlagsChangeFeatureStateTenantFeatureIdPut(tenant, featureId, <FeatureStateDto>{
                        isEnabled,
                        isEnabledForAdmins,
                    })
                    .pipe(
                        map((data) => fromShared.ChangeFeatureStateSuccess({ tenant })),
                        catchError(() => of(fromShared.ChangeFeatureStateFailed({ tenant })))
                    )
            )
        )
    );

    enableFeatureOnAllTenants = createEffect(() =>
        this.actions$.pipe(
            ofType(fromShared.EnableFeatureOnAllTenants),
            mergeMap((event: ChangeFeatureStateOnAllTenantsDto) => {
                const state = <AllTenantsFeatureStateDto>{
                    isEnabled: event.isEnabled,
                    isEnabledForAdmins: event.isEnabledForAdmins,
                    tenants: event.tenants,
                };
                return this.featureFlagsApiService
                    .apiFeatureFlagsChangeFeatureStateOnAllTenantsFeatureIdAllTenantsPut(event.featureId, state)
                    .pipe(
                        map((data) => fromShared.EnableFeatureOnAllTenantsSuccess(event)),
                        catchError(() => of(fromShared.EnableFeatureOnAllTenantsFailed(event)))
                    );
            })
        )
    );

    featureFlagChanged = createEffect(() =>
        this.actions$.pipe(
            ofType(fromShared.FeatureStateChanged),
            mergeMap(({ featureId, isEnabled, isEnabledForAdmins }) => {
                this.featureFlagsService.handleFeatureFlagChanged(featureId, isEnabled, isEnabledForAdmins);

                return this.authService.currentUserValue?.role === Role.Admin ? [fromShared.LoadAllFeatures({ reload: true })] : [];
            })
        )
    );

    changeThisTenantFeatureState = createEffect(() =>
        this.actions$.pipe(
            ofType(fromShared.ChangeThisTenantFeatureState),
            mergeMap(({ featureId, isEnabled, isEnabledForAdmins }) =>
                this.featureFlagsApiService
                    .apiFeatureFlagsChangeFeatureStateFeatureIdPut(featureId, <FeatureStateDto>{
                        isEnabled,
                        isEnabledForAdmins,
                    })
                    .pipe(
                        mergeMap(() => [
                            fromShared.ChangeThisTenantFeatureStateSuccess({ featureId }),
                            fromShared.LoadAllFeatures({ reload: true }),
                        ]),
                        catchError(() => of(fromShared.ChangeThisTenantFeatureStateFailed({ featureId })))
                    )
            )
        )
    );
}

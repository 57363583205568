import { UpdateAppFaviconDto } from './../../_models/updateAppFaviconDto';
import { ThemeSpecifications, LoadSpecifications, UpdateFavicon, DeleteInternetPortalTheme, EditTheme } from './../../state/themes.actions';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IDirtyFormComponent } from 'src/app/shared/_components/confirm-leave/confirm-leave.guard';
import { NavService } from 'src/app/frame/_services/nav.service';
import { UpdateSpecifications } from '../../state';
import { Observable } from 'rxjs';
import * as fromThemes from '../../state/';
import { Store } from '@ngrx/store';
import { UpdateAppThemeDto } from '../../_models/updateAppThemeDto';
import { EditAppThemeDto } from '../../_models/editAppThemeDto';

@Component({
    templateUrl: './theme-shell.component.html',
    changeDetection: ChangeDetectionStrategy.Default
})
export class ThemeShellComponent implements OnInit, IDirtyFormComponent {
    theme$: Observable<ThemeSpecifications>;
    loading$: Observable<boolean>;
    saving$: Observable<boolean>;
    savingFavicon$: Observable<boolean>;

    constructor(
        private navService: NavService,
        private store: Store<fromThemes.ThemesState>
    ) {
        this.navService.setLocation('frame.menu.app-themes');
    }

    ngOnInit() {
        this.store.dispatch(new LoadSpecifications('Theme Component'));
        this.theme$ = this.store.select(fromThemes.getThemeSpecifications);
        this.loading$ = this.store.select(fromThemes.getLoadingThemeSpecifications);
        this.saving$ = this.store.select(fromThemes.getSavingThemeSpecifications);
        this.savingFavicon$ = this.store.select(fromThemes.getSavingFavicon);
    }

    isDirty(): boolean {
        return false;
    }

    update(theme: UpdateAppThemeDto) {
        this.store.dispatch(new UpdateSpecifications('Theme Component', theme));
    }

    updateFavicon(favicon: UpdateAppFaviconDto) {
        this.store.dispatch(new UpdateFavicon('Theme Component', favicon));
    }

    delete(menuItemId: number) {
        this.store.dispatch(new DeleteInternetPortalTheme('Theme Component', menuItemId));
    }

    edit(theme: EditAppThemeDto) {
        this.store.dispatch(new EditTheme('Theme Component', theme));
    }
}

import { Permission } from 'src/app/auth/_models/permission';
import { Role } from './../../auth/_models/role';
import { ApplicationName } from './../../shared/_models/application-name';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MenuSection } from 'src/app/frame/menu/menu.component';

export const getDefaultPageByUserRole = (role: Role, permissions: string[], useNewTimesheet: boolean): string => {
    // Redirect FW/CP to timesheet if they have permissions.
    if (role == Role.Contact && permissions.indexOf(Permission.OrganisationTimesheet) > -1) {
        return useNewTimesheet ? '/timesheets/week/W_PREVIOUS' : `/nl/tab/${ApplicationName.RelationPortal}/OrganisationTimesheet`;
    }
    if (role == Role.Flexworker && permissions.indexOf(Permission.FlexworkerTimesheet) > -1) {
        return useNewTimesheet ? 'timesheets' : `/nl/tab/${ApplicationName.FlexworkerPortal}/FlexworkerTimesheet`;
    }

    // Otherwise the first menu-item / NO ACCESS if the menu is empty.
    let menu = buildMenu(role, permissions, useNewTimesheet);
    return !menu?.length ? '/no-access' : menu[0].menuItems[0].routerLink;
};

export const buildMenu = (role: Role, permissions: string[], useNewTimesheet: boolean): Array<MenuSection> => {
    let menu = [];
    if (role === Role.Admin) {
        menu = [
            {
                icon: 'fal fa-pen-nib',
                text: <string>_('frame.menu.registration'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: '/employees/all-employees',
                        text: <string>_('frame.menu.employees'),
                        visible: true,
                    },
                    {
                        routerLink: '/flexworkers/all-flexworkers',
                        text: <string>_('frame.menu.flexworkers'),
                        visible: true,
                    },
                    {
                        routerLink: '/contacts/all-contacts',
                        text: <string>_('frame.menu.contacts'),
                        visible: true,
                    },
                ],
            },
            {
                icon: 'fal fa-clock',
                text: <string>_('frame.menu.worked-hours'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: '/worksheets/select-a-day',
                        text: <string>_('frame.menu.worksheets'),
                        permission: Permission.Worksheets,
                        visible: true,
                    },
                ],
            },
            {
                icon: 'fal fa-sliders-h',
                text: <string>_('frame.menu.settings'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: '/settings/new-features',
                        text: <string>_('frame.menu.new-features'),
                        visible: true,
                    },
                ],
            },
        ];
    }

    if (role == Role.Contact) {
        menu = [
            {
                icon: 'fal fa-home',
                text: <string>_('frame.menu.welcome'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: '/organisation-portal/choose-your-portal',
                        text: <string>_('frame.menu.your-portal'),
                        visible: true,
                    },
                ],
            },
            {
                icon: 'fal fa-pen-nib',
                text: <string>_('frame.menu.registration'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: '/organisation-portal/your-organisation-information',
                        text: <string>_('frame.menu.your-information'),
                        permission: Permission.YourInformation,
                        visible: true,
                    },
                    {
                        routerLink: '/organisation-portal/downloads',
                        text: <string>_('frame.menu.documents'),
                        permission: Permission.Downloads,
                        visible: true,
                    },
                    {
                        routerLink: `/nl/tab/${ApplicationName.RelationPortal}/Flexworkers`,
                        text: <string>_('frame.menu.flexworkers'),
                        permission: Permission.Flexworkers,
                        visible: true,
                    },
                    {
                        routerLink: `/nl/tab/${ApplicationName.RelationPortal}/CostCenters`,
                        text: <string>_('frame.menu.cost-centers'),
                        permission: Permission.CostCenters,
                        visible: true,
                    },
                ],
            },
            {
                icon: 'fal fa-calendar-alt',
                text: <string>_('frame.menu.plan'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: `/organisation-portal/requests`,
                        text: <string>_('frame.menu.requests'),
                        permission: Permission.Requests,
                        visible: true,
                    },
                    {
                        routerLink: `/nl/tab/${ApplicationName.RelationPortal}/WorkPlanning`,
                        text: <string>_('frame.menu.work-planning'),
                        permission: Permission.WorkPlanning,
                        visible: true,
                    },
                ],
            },
            {
                icon: 'fal fa-clock',
                text: <string>_('frame.menu.worked-hours'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: `/nl/tab/${ApplicationName.RelationPortal}/OrganisationTimesheet`,
                        text: <string>_('frame.menu.timesheets'),
                        permission: Permission.OrganisationTimesheet,
                        visible: !useNewTimesheet,
                    },
                    {
                        routerLink: `/nl/tab/${ApplicationName.RelationPortal}/ApproveHours`,
                        text: <string>_('frame.menu.approve-hours'),
                        permission: Permission.ApproveHours,
                        visible: true,
                    },
                    {
                        routerLink: '/timesheets/week/W_CURRENT',
                        text: <string>_('frame.menu.this-week'),
                        permission: Permission.OrganisationTimesheet,
                        visible: useNewTimesheet,
                    },
                    {
                        routerLink: '/timesheets/week/W_PREVIOUS',
                        text: <string>_('frame.menu.last-week'),
                        permission: Permission.OrganisationTimesheet,
                        visible: useNewTimesheet,
                    },
                    {
                        routerLink: '/timesheets/weeks',
                        text: <string>_('frame.menu.all-weeks'),
                        permission: Permission.OrganisationTimesheet,
                        visible: useNewTimesheet,
                    },
                    {
                        routerLink: `/nl/tab/${ApplicationName.RelationPortal}/StatementConfirmations`,
                        text: <string>_('frame.menu.declarations'),
                        permission: Permission.StatementConfirmations,
                        visible: true,
                    },
                ],
            },
            {
                icon: 'fal fa-money-bill-wave',
                text: <string>_('frame.menu.invoicing'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: `/nl/tab/${ApplicationName.RelationPortal}/HoursRedirect`,
                        text: <string>_('frame.menu.hours'),
                        permission: Permission.HoursRedirect,
                        visible: true,
                    },
                    {
                        routerLink: '/organisation-portal/invoices',
                        text: <string>_('frame.menu.invoices'),
                        permission: Permission.Invoices,
                        visible: true,
                    },
                    {
                        routerLink: '/organisation-portal/download-payslips',
                        text: <string>_('frame.menu.payslips'),
                        permission: Permission.DownloadPayslips,
                        visible: true,
                    },
                ],
            },
        ];
    }

    if (role === Role.Employee) {
        menu = [
            {
                icon: 'fal fa-clock',
                text: <string>_('frame.menu.worked-hours'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: '/worksheets/select-a-day',
                        text: <string>_('frame.menu.worksheets'),
                        permission: Permission.Worksheets,
                        visible: true,
                    },
                ],
            },
        ];
    }

    if (role === Role.Flexworker) {
        menu = [
            {
                icon: 'fal fa-pen-nib',
                text: <string>_('frame.menu.registration'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: '/flexworker-portal/your-information',
                        text: <string>_('frame.menu.your-information'),
                        permission: Permission.YourInformation,
                        visible: true,
                    },
                    {
                        routerLink: '/flexworker-portal/downloads',
                        text: <string>_('frame.menu.documents'),
                        permission: Permission.Downloads,
                        visible: true,
                    },
                    {
                        routerLink: '/flexworker-portal/health-insurance-pass',
                        text: <string>_('frame.menu.health-insurance-pass'),
                        permission: Permission.InsuranceDocuments,
                        visible: true,
                    },
                    {
                        routerLink: '/flexworker-portal/health-insurance-documents',
                        text: <string>_('frame.menu.health-insurance-documents'),
                        permission: Permission.InsuranceDocuments,
                        visible: true,
                    },
                ],
            },
            {
                icon: 'fal fa-calendar-alt',
                text: <string>_('frame.menu.plan'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: '/flexworker-portal/absences',
                        text: <string>_('frame.menu.illness-absence'),
                        permission: Permission.AbsenceAndAvailabilityList,
                        visible: true,
                    },
                    {
                        routerLink: '/flexworker-portal/housing',
                        text: <string>_('frame.menu.housing'),
                        permission: Permission.Housing,
                        visible: true,
                    },
                ],
            },
            {
                icon: 'fal fa-clock',
                text: <string>_('frame.menu.worked-hours'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: 'timesheets',
                        text: <string>_('frame.menu.timesheets'),
                        permission: Permission.FlexworkerTimesheet,
                        visible: useNewTimesheet,
                    },
                    {
                        routerLink: `/nl/tab/${ApplicationName.FlexworkerPortal}/FlexworkerTimesheet`,
                        text: <string>_('frame.menu.timesheets'),
                        permission: Permission.FlexworkerTimesheet,
                        visible: !useNewTimesheet,
                    },
                    {
                        routerLink: `/nl/tab/${ApplicationName.FlexworkerPortal}/Reservation`,
                        text: <string>_('frame.menu.reservation'),
                        permission: Permission.Reservation,
                        visible: true,
                    },
                    {
                        routerLink: '/flexworker-portal/travel-statements',
                        text: <string>_('frame.menu.travel-statements'),
                        permission: Permission.TravelStatements,
                        visible: true,
                    },
                    {
                        routerLink: '/flexworker-portal/statements-status',
                        text: <string>_('frame.menu.statements-status'),
                        permission: Permission.FlexworkerStatementsStatus,
                        visible: true,
                    },
                ],
            },
            {
                icon: 'fal fa-money-bill-wave',
                text: <string>_('frame.menu.payroll'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: '/flexworker-portal/payslips',
                        text: <string>_('frame.menu.payslips'),
                        permission: Permission.Payslips,
                        visible: true,
                    },
                    {
                        routerLink: '/flexworker-portal/annual-statements',
                        text: <string>_('frame.menu.annual-statements'),
                        permission: Permission.AnnualStatements,
                        visible: true,
                    },
                ],
            },
        ];
    }

    if (role === Role.JobSeeker) {
        menu = [
            {
                icon: 'fal fa-pen-nib',
                text: <string>_('frame.menu.registration'),
                showText: true,
                showOpen: true,
                visible: true,
                menuItems: [
                    {
                        routerLink: '/registration-portal/registration',
                        text: <string>_('frame.menu.your-information'),
                        visible: true,
                    },
                ],
            },
        ];
    }

    menu.forEach((menuSection) => {
        menuSection.menuItems = menuSection.menuItems.filter((mi) => mi.visible && (!mi.permission || permissions.includes(mi.permission)));

        // remove menu section if all items were filtered out
        menuSection.visible = menuSection.visible && menuSection.menuItems.length > 0;
    });

    if (!menu?.length) {
        return [];
    }

    return menu.filter((ms) => ms.visible);
};

import { Component, Input } from '@angular/core';

@Component({
    selector: 'fp-current-user',
    templateUrl: 'current-user.component.html',
    styleUrls: ['current-user.component.css'],
})
export class CurrentUserComponent {
    @Input() language: string;
    @Input() fullName: string;
    @Input() impersonatedByFullName: string;

    constructor() {}

    showImpersonatedBy = false;
    onShowImpersonatedBy() {
        this.showImpersonatedBy = !this.showImpersonatedBy;
    }
}

import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileService } from '../../_services/file.service';

@Component({
    selector: 'fp-logo-header',
    templateUrl: 'logo-header.component.html',
    styleUrls: ['logo-header.component.scss'],
})
export class LogoHeaderComponent {
    public $actualHeight: Observable<number>;
    private _logoUrl: string;
    public enforceFlexportalTheme = false;

    @Input() hideOnSmallScreen = false;
    @Input() set logoUrl(value: string) {
        this._logoUrl = value !== null ? value : './assets/images/logo-url.png';
        this.enforceFlexportalTheme = value === null;
        this.$actualHeight = this.imageService.getImageSize(value).pipe(
            map((size) => {
                return size.height;
            })
        );
    }

    get logoUrl(): string {
        return this._logoUrl;
    }

    constructor(private imageService: FileService) {}
}

import { UpdateAppFaviconResponse } from './../../rest/model/updateAppFaviconResponse';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { AppThemesApiService } from 'src/app/rest/api/appThemesApi.service';
import { catchError, tap, switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromActions from './themes.actions';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { ThemeStyleSheetService } from 'src/app/frame/_services/theme-style-sheet-service';
import { Theme } from './themes.actions';

@Injectable()
export class ThemesEffects {
    constructor(
        private actions$: Actions,
        private appThemesApiService: AppThemesApiService,
        private toastr: ToastrService,
        private translateService: TranslateService,
        private themeStyleSheetService: ThemeStyleSheetService
    ) { }


    load$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ThemesActionTypes.Load),
        switchMap((action: fromActions.Load) =>
            this.appThemesApiService.apiAppThemesGetAppThemeGet().pipe(
                switchMap(data =>
                    of(
                        new fromActions.LoadSuccess(
                            this.newOrigin(action.origin),
                            <fromActions.Theme>data
                        ),
                        new fromActions.ThemeChanged(
                            this.newOrigin(action.origin),
                            <fromActions.Theme>data
                        )
                    )
                ),
                catchError(() =>
                    of(new fromActions.UpdateSpecificationsFailed(this.newOrigin(action.origin)))
                )
            )
        )
    ));


    loadSpecifications$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ThemesActionTypes.LoadSpecifications),
        switchMap((action: fromActions.LoadSpecifications) =>
            this.appThemesApiService.apiAppThemesGetAppThemesGet().pipe(
                map(data =>
                    new fromActions.LoadSpecificationsSuccess(
                        this.newOrigin(action.origin),
                        <fromActions.ThemeSpecifications>data
                    )
                ),
                catchError(() =>
                    of(new fromActions.UpdateSpecificationsFailed(this.newOrigin(action.origin)))
                )
            )
        )
    ));


    update$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ThemesActionTypes.UpdateSpecifications),
        switchMap((action: fromActions.UpdateSpecifications) =>
            this.appThemesApiService.apiAppThemesUpdateAppThemePost(action.payload.menuItemId || 0, action.payload.accentColor, action.payload.headerColor, action.payload.logo).pipe(
                switchMap((theme: Theme) => {
                    this.translateService
                        .get(<string>_('shared.messages.the-changes-are-saved'))
                        .subscribe(text => this.toastr.success(text));

                    return action.payload.menuItemId > 0
                        ? of(new fromActions.UpdateSpecificationsSuccess(this.newOrigin(action.origin), theme))
                        : of(
                            new fromActions.UpdateSpecificationsSuccess(this.newOrigin(action.origin), theme),
                            new fromActions.ThemeChanged(this.newOrigin(action.origin), theme));
                }),
                catchError(() =>
                    of(new fromActions.UpdateSpecificationsFailed(this.newOrigin(action.origin)))
                )
            )
        )
    ));


    updateFavicon$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ThemesActionTypes.UpdateFavicon),
        switchMap((action: fromActions.UpdateFavicon) =>
            this.appThemesApiService.apiAppThemesUpdateAppFaviconPost(action.payload.favicon).pipe(
                switchMap((favicon: UpdateAppFaviconResponse) => {
                    this.translateService
                        .get(<string>_('shared.messages.the-changes-are-saved'))
                        .subscribe(text => this.toastr.success(text));

                    return of(
                        new fromActions.UpdateFaviconSuccess(this.newOrigin(action.origin), favicon)
                    );
                }),
                catchError(() =>
                    of(new fromActions.UpdateFaviconFailed(this.newOrigin(action.origin)))
                )
            )
        )
    ));


    deleteInternetPortalTheme$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ThemesActionTypes.DeleteInternetPortalTheme),
        switchMap((action: fromActions.DeleteInternetPortalTheme) =>
            this.appThemesApiService.apiAppThemesMenuItemIdDelete(action.payload).pipe(
                switchMap(() => {
                    this.translateService
                        .get(<string>_('settings.app-themes.theme-is-deleted'))
                        .subscribe(text => this.toastr.success(text));

                    return of(
                        new fromActions.DeleteInternetPortalThemeSuccess(this.newOrigin(action.origin))
                    );
                }),
                catchError(() =>
                    of(new fromActions.DeleteInternetPortalThemeFailed(this.newOrigin(action.origin)))
                )
            )
        )
    ));


    themeChanged$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ThemesActionTypes.ThemeChanged),
        tap((action: fromActions.ThemeChanged) =>
            this.themeStyleSheetService.updateAppThemeStyleSheet(action.payload)
        )
    ), { dispatch: false });


    faviconChanged$ = createEffect(() => this.actions$.pipe(
        ofType(fromActions.ThemesActionTypes.UpdateFaviconSuccess),
        tap((action: fromActions.UpdateFaviconSuccess) =>
            this.themeStyleSheetService.updateFavicon(action.payload.favicons)
        )
    ), { dispatch: false });

    newOrigin(prevOrigin: string): string {
        return prevOrigin + ' > Themes Effects';
    }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { TimesheetsDashboardApiService } from 'src/app/rest';
import * as fromActions from './employee-facilities-dashboard.actions';

@Injectable()
export class EmployeeFacilitiesDashboardEffects {
    constructor(private actions$: Actions, private timesheetsDashboardApiService: TimesheetsDashboardApiService) {}

    loadEmployeeFacilitiesStatements$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.LoadEmployeeTimesheetStatements),
            exhaustMap((action) => {
                return this.timesheetsDashboardApiService
                    .apiTimesheetsDashboardGetTimesheetStatementsMenuItemIdPeriodIdGet(action.menuItemId, action.periodId)
                    .pipe(
                        map((statements) => fromActions.LoadEmployeeTimesheetStatementsSuccess({ statements })),
                        catchError((error) => of(fromActions.LoadEmployeeTimesheetStatementsFailure(error)))
                    );
            })
        )
    );

    loadStatementVersions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.LoadStatementVersion),
            exhaustMap((action) =>
                this.timesheetsDashboardApiService.apiTimesheetsDashboardGetTimesheetStatementHistoryIdGet(action.statementId).pipe(
                    map((statementHistory) => fromActions.LoadStatementVersionSuccess({ statementHistory })),
                    catchError((error) => of(fromActions.LoadStatementVersionFailure(error)))
                )
            )
        )
    );
}

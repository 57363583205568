<div *ngFor="let field of fieldsData; let index = index; trackBy: trackByFn" class="row">
    <label class="field-label align-self-center">{{ field.componentDto.name }}</label>
    <ng-container [ngSwitch]="field.componentDto.componentUnit">
        <ng-container *ngSwitchCase="fieldType.Amount">
            <div>
                <span class="align-selft-center value"> {{ field.value || 0 }}</span>
                <span class="align-self-center">euro</span>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.Kilometers">
            <div>
                <span class="align-self-center">{{ field.value || 0 }}</span>
                <span class="align-self-center"
                    >km x € {{ field.componentDto.multiplier }} = €
                    {{ field.value * field.componentDto.multiplier | number: '1.0-2' }}</span
                >
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.Pieces">
            <div>
                <span class="align-self-center value">{{ field.value || 0 }}</span>
                <span class="align-self-center"
                    >x € {{ field.componentDto.multiplier }} = €
                    {{ field.value * field.componentDto.multiplier | number: '1.0-2' }}
                </span>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.Hour">
            <div>
                <span class="align-self-center value">{{ field.value || 0 }}</span>
                <span class="align-self-center"
                    >h x € {{ field.componentDto.multiplier }} = €
                    {{ field.value || 0 * field.componentDto.multiplier | number: '1.2-2' }}
                </span>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.WorkedDay">
            <div>
                <span class="align-self-center"
                    >€ {{ field.value || 0 | number: '1.2-2' }} per worked day</span
                >
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.WorkedHour">
            <div>
                <span class="align-self-center"
                    >€ {{ field.value || 0 | number: '1.2-2' }} per worked hour</span
                >
            </div>
        </ng-container>
    </ng-container>
</div>

<div class="hours-row">
    <div class="header">
        <div class="hours-row-cell" *ngFor="let date of currentWeekDaysRange">
            {{ date | date: 'EEE d' }}
            <div *ngIf="timesheetMode === timesheetModeValues.StartEndBreakTime" class="start-end-break">
                {{ 'timesheets.start_end_break' | translate }}
            </div>
        </div>
        <div class="hours-row-cell">
            {{ 'shared.total' | translate | lowercase }}
        </div>
    </div>
    <div class="content">
        <div *ngFor="let hour of hours; let index" class="hours-row-cell">
            <ng-container *ngIf="timesheetMode === timesheetModeValues.DayTotal">
                {{ hour?.duration }}
            </ng-container>
            <ng-container *ngIf="timesheetMode === timesheetModeValues.StartEndBreakTime">
                <span class="start-end-break">{{ hour?.startTime }}</span
                >&nbsp; <span class="start-end-break">{{ hour?.endTime }}</span
                >&nbsp;
                <span class="start-end-break">{{ hour?.breakTime }}</span>
            </ng-container>
        </div>
        <div class="hours-row-cell">
            {{ total }}
        </div>
    </div>
</div>

/**
 * Flexportal API
 *   <p>This is the documentation for the Flexportal API.</p>    <h3>Authorization</h3>  <p>Most API endpoints require authorization. Do this by adding an authorization header wiht a JWT   token in every request.</p>    <p><code>Authorization: Bearer [JWT Token goes here]</code></p>    <p>Get the JWT token through the <strong>/api/Authentication/UsernameAuthenticate</strong>  endpoint. This endpoint requires the email address/username and password of a Flexportal user.</p>    <p>Most API endpoints are specifically tied to a particular user. This is especially true for API  endpoints for flexworkers and contact persons. Some API endpoints have a more generic use. These   typically require the JWT token for an employee.</p>    <p>If you want to call employee API endpoints you may opt to create a new Flexportal user for the   specific goal of calling the API. Create the user and set the password as you would normally do.   You can initially use your own email address to set the password and change it afterward.</p>    <h3>Conventions</h3>  <p>We strive for clarity by following these conventions.</p>  <ul>      <li>No hyphens, dashes, or underscores</li>      <li>Casing: camelCase</li>      <li>flexworkerId or flexworkerNumber are more clear than Id or Number</li>  </ul>  <p>Please let us know if you spot an endpoint that does not adhere to these conventions.</p>  
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateShiftConfigurationDto } from '../model/createShiftConfigurationDto';
// @ts-ignore
import { CreateShiftResponseDto } from '../model/createShiftResponseDto';
// @ts-ignore
import { OrganisationUnravelSettingDto } from '../model/organisationUnravelSettingDto';
// @ts-ignore
import { ShiftConfigurationDto } from '../model/shiftConfigurationDto';
// @ts-ignore
import { UpdateShiftConfigurationDto } from '../model/updateShiftConfigurationDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class ShiftsApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * BETA - Get unravel settings for an organization
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Admin, ApiAccess, Employee&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param organisationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShiftsOrganisationsOrganisationIdGet(organisationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<OrganisationUnravelSettingDto>;
    public apiShiftsOrganisationsOrganisationIdGet(organisationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<OrganisationUnravelSettingDto>>;
    public apiShiftsOrganisationsOrganisationIdGet(organisationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<OrganisationUnravelSettingDto>>;
    public apiShiftsOrganisationsOrganisationIdGet(organisationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling apiShiftsOrganisationsOrganisationIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<OrganisationUnravelSettingDto>(`${this.configuration.basePath}/api/shifts/organisations/${encodeURIComponent(String(organisationId))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Update unravel settings for an organization
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Admin, ApiAccess, Employee&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param organisationId 
     * @param organisationUnravelSettingDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShiftsOrganisationsOrganisationIdPut(organisationId: number, organisationUnravelSettingDto?: OrganisationUnravelSettingDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiShiftsOrganisationsOrganisationIdPut(organisationId: number, organisationUnravelSettingDto?: OrganisationUnravelSettingDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiShiftsOrganisationsOrganisationIdPut(organisationId: number, organisationUnravelSettingDto?: OrganisationUnravelSettingDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiShiftsOrganisationsOrganisationIdPut(organisationId: number, organisationUnravelSettingDto?: OrganisationUnravelSettingDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling apiShiftsOrganisationsOrganisationIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/shifts/organisations/${encodeURIComponent(String(organisationId))}`,
            organisationUnravelSettingDto,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Get list of shifts for an organisation
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Admin, ApiAccess, Employee&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param organisationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShiftsOrganisationsOrganisationIdShiftsGet(organisationId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<Array<ShiftConfigurationDto>>;
    public apiShiftsOrganisationsOrganisationIdShiftsGet(organisationId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<Array<ShiftConfigurationDto>>>;
    public apiShiftsOrganisationsOrganisationIdShiftsGet(organisationId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<Array<ShiftConfigurationDto>>>;
    public apiShiftsOrganisationsOrganisationIdShiftsGet(organisationId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling apiShiftsOrganisationsOrganisationIdShiftsGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<Array<ShiftConfigurationDto>>(`${this.configuration.basePath}/api/shifts/organisations/${encodeURIComponent(String(organisationId))}/shifts`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Create a shift for an organisation
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Admin, ApiAccess, Employee&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;&lt;h3&gt;Fields&lt;/h3&gt;  &lt;table&gt;    &lt;thead&gt;      &lt;tr&gt;        &lt;td&gt;Field&lt;/td&gt;        &lt;td&gt;Type&lt;/td&gt;        &lt;td&gt;Required&lt;/td&gt;        &lt;td&gt;Validation rules / description&lt;/td&gt;      &lt;/tr&gt;    &lt;/thead&gt;    &lt;tbody&gt;      &lt;tr&gt;        &lt;td&gt;Name&lt;/td&gt;        &lt;td&gt;string(128)&lt;/td&gt;        &lt;td&gt;Yes&lt;/td&gt;        &lt;td&gt;&lt;/td&gt;      &lt;/tr&gt;      &lt;tr&gt;        &lt;td&gt;UnravelSchemeId&lt;/td&gt;        &lt;td&gt;number&lt;/td&gt;        &lt;td&gt;Yes&lt;/td&gt;        &lt;td&gt;Use the value 0 (zero) to use the default unravelscheme&lt;/td&gt;      &lt;/tr&gt;      &lt;tr&gt;        &lt;td&gt;BreakTimes&lt;/td&gt;        &lt;td&gt;&lt;/td&gt;        &lt;td&gt;No&lt;/td&gt;        &lt;td&gt;&lt;/td&gt;      &lt;/tr&gt;      &lt;tr&gt;        &lt;td&gt;Active&lt;/td&gt;        &lt;td&gt;boolean&lt;/td&gt;        &lt;td&gt;Yes&lt;/td&gt;        &lt;td&gt;&lt;/td&gt;      &lt;/tr&gt;    &lt;/tbody&gt;  &lt;/table&gt;
     * @param organisationId 
     * @param createShiftConfigurationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShiftsOrganisationsOrganisationIdShiftsPost(organisationId: number, createShiftConfigurationDto?: CreateShiftConfigurationDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<CreateShiftResponseDto>;
    public apiShiftsOrganisationsOrganisationIdShiftsPost(organisationId: number, createShiftConfigurationDto?: CreateShiftConfigurationDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<CreateShiftResponseDto>>;
    public apiShiftsOrganisationsOrganisationIdShiftsPost(organisationId: number, createShiftConfigurationDto?: CreateShiftConfigurationDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<CreateShiftResponseDto>>;
    public apiShiftsOrganisationsOrganisationIdShiftsPost(organisationId: number, createShiftConfigurationDto?: CreateShiftConfigurationDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (organisationId === null || organisationId === undefined) {
            throw new Error('Required parameter organisationId was null or undefined when calling apiShiftsOrganisationsOrganisationIdShiftsPost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.post<CreateShiftResponseDto>(`${this.configuration.basePath}/api/shifts/organisations/${encodeURIComponent(String(organisationId))}/shifts`,
            createShiftConfigurationDto,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Delete a shift
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Admin, ApiAccess, Employee&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param shiftId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShiftsShiftIdDelete(shiftId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiShiftsShiftIdDelete(shiftId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiShiftsShiftIdDelete(shiftId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiShiftsShiftIdDelete(shiftId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (shiftId === null || shiftId === undefined) {
            throw new Error('Required parameter shiftId was null or undefined when calling apiShiftsShiftIdDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/shifts/${encodeURIComponent(String(shiftId))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Get a specific shift
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Admin, ApiAccess, Employee&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;
     * @param shiftId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShiftsShiftIdGet(shiftId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<ShiftConfigurationDto>;
    public apiShiftsShiftIdGet(shiftId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpResponse<ShiftConfigurationDto>>;
    public apiShiftsShiftIdGet(shiftId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<HttpEvent<ShiftConfigurationDto>>;
    public apiShiftsShiftIdGet(shiftId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json', context?: HttpContext}): Observable<any> {
        if (shiftId === null || shiftId === undefined) {
            throw new Error('Required parameter shiftId was null or undefined when calling apiShiftsShiftIdGet.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<ShiftConfigurationDto>(`${this.configuration.basePath}/api/shifts/${encodeURIComponent(String(shiftId))}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * BETA - Update a shift
     * &lt;h3&gt;Version&lt;/h3&gt;&lt;p&gt;&lt;code&gt;BETA&lt;/code&gt; Breaking changes may occur without notice&lt;/p&gt;&lt;br/&gt;&lt;h3&gt;Authorisation&lt;/h3&gt;&lt;p&gt;Roles: Admin, ApiAccess, Employee&lt;/p&gt;&lt;p&gt;see /api/Authentication/UsernameAuthenticate&lt;/p&gt;&lt;h3&gt;Fields&lt;/h3&gt;  &lt;table&gt;    &lt;thead&gt;      &lt;tr&gt;        &lt;td&gt;Field&lt;/td&gt;        &lt;td&gt;Type&lt;/td&gt;        &lt;td&gt;Required&lt;/td&gt;        &lt;td&gt;Validation rules / description&lt;/td&gt;      &lt;/tr&gt;    &lt;/thead&gt;    &lt;tbody&gt;      &lt;tr&gt;        &lt;td&gt;Name&lt;/td&gt;        &lt;td&gt;string(128)&lt;/td&gt;        &lt;td&gt;Yes&lt;/td&gt;        &lt;td&gt;&lt;/td&gt;      &lt;/tr&gt;      &lt;tr&gt;        &lt;td&gt;UnravelSchemeId&lt;/td&gt;        &lt;td&gt;number&lt;/td&gt;        &lt;td&gt;Yes&lt;/td&gt;        &lt;td&gt;Use the value 0 (zero) to use the default unravelscheme&lt;/td&gt;      &lt;/tr&gt;      &lt;tr&gt;        &lt;td&gt;BreakTimes&lt;/td&gt;        &lt;td&gt;&lt;/td&gt;        &lt;td&gt;No&lt;/td&gt;        &lt;td&gt;&lt;/td&gt;      &lt;/tr&gt;    &lt;/tbody&gt;  &lt;/table&gt;
     * @param shiftId 
     * @param updateShiftConfigurationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShiftsShiftIdPut(shiftId: number, updateShiftConfigurationDto?: UpdateShiftConfigurationDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public apiShiftsShiftIdPut(shiftId: number, updateShiftConfigurationDto?: UpdateShiftConfigurationDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public apiShiftsShiftIdPut(shiftId: number, updateShiftConfigurationDto?: UpdateShiftConfigurationDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public apiShiftsShiftIdPut(shiftId: number, updateShiftConfigurationDto?: UpdateShiftConfigurationDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (shiftId === null || shiftId === undefined) {
            throw new Error('Required parameter shiftId was null or undefined when calling apiShiftsShiftIdPut.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (oauth2) required
        localVarCredential = this.configuration.lookupCredential('oauth2');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/shifts/${encodeURIComponent(String(shiftId))}`,
            updateShiftConfigurationDto,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromFrame from '../../state';
import { AuthService } from 'src/app/auth/_services';

@Component({
    selector: 'fp-current-user-shell',
    templateUrl: './current-user-shell.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentUserShellComponent {
    language$: Observable<string>;

    fullName: string;
    impersonatedByFullName?: string;

    constructor(
        private authService: AuthService,
        private store: Store<fromFrame.State>
    ) {
        this.language$ = this.store.pipe(select(fromFrame.getLanguage));

        this.authService.currentUser.subscribe((x) => {
            this.fullName = x && x.fullName;
            this.impersonatedByFullName = x && x.impersonatedByFullName;
        });
    }
}

<div class="table">
    <div class="table-header">
        <div class="table-cell fw-info"></div>
        <div class="table-cell organisation-status">
            <div class="table-cell-header">{{ 'shared.organisation' | translate }}</div>
            <div class="table-cell-content">
                <span>{{ 'shared.saved' | translate }}</span>
                <span>{{ 'shared.approved' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="table-content">
        <div class="organisation-group-wrapper" *ngFor="let os of organisationStatements">
            <div class="organisation-group-header">
                {{ os.organisation.name }}
            </div>

            <div class="organisation-group" *ngFor="let statement of os.statements">
                <div class="table-cell fw-info">
                    <fp-flexworker-info
                        [statement]="statement"
                        [flexworker]="statement.flexworker"
                        [showCostCenter]="true"
                        [showWorkingDays]="true"
                        maxWidthPx="100%"
                    ></fp-flexworker-info>
                </div>
                <div class="table-cell organisation-status-content">
                    <div class="organisation-status-value">
                        <div [class.saved]="statement.savedOn">{{ statement.savedOn | date: 'd-MM-yy' }}</div>
                        <div [class.approved]="statement.approvedOn">{{ statement.approvedOn | date: 'd-MM-yy' }}</div>
                    </div>
                    <button class="btn btn-secondary view-history-btn" (click)="navigateToVersion(statement.id)">
                        <i class="fal fa-history"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

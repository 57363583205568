import { environment } from './../../../environments/environment';
import { HubConnection } from '@aspnet/signalr';
import { Injectable, Inject } from '@angular/core';
import { AuthService } from 'src/app/auth/_services';
import * as signalR from '@aspnet/signalr';
import { BASE_PATH } from '../../rest/variables';
import * as fromFrame from '../../frame/state';
import * as fromThemes from '../../themes/state';
import * as fromShared from '../../shared/state';
import { Store } from '@ngrx/store';
import { Theme } from '../../themes/state';
import { FeatureFlagChanged } from '../../settings/models/featureStateChanged';

@Injectable({
    providedIn: 'root',
})
export class SignalRService {
    private _appConnection: SignalRConnection | undefined;
    private _featureFlagsConnection: SignalRConnection | undefined;
    protected basePath = '';

    constructor(private authService: AuthService, private store: Store<any>, @Inject(BASE_PATH) basePath: string) {
        this.basePath = basePath;
        this.authService.currentUser.subscribe((x) => {
            if (x === null) {
                this.stop(this._appConnection);
            } else {
                this.start(this._appConnection);
            }
        });
    }

    async init() {
        this._appConnection = {
            connection: new signalR.HubConnectionBuilder()
                .withUrl(`${this.basePath}/flexportalhub`, {
                    accessTokenFactory: () => this.authService.currentUserValue?.jwtToken,
                })
                .configureLogging(signalR.LogLevel.Information)
                .build(),
            connectionStatus: 'Not connected',
            id: 'App',
        };

        this._featureFlagsConnection = {
            connection: new signalR.HubConnectionBuilder()
                .withUrl(`${environment.FEATURE_FLAGS_PATH}/feature-flags-hub`)
                .configureLogging(signalR.LogLevel.Information)
                .build(),
            connectionStatus: 'Not connected',
            id: 'FeatureFlags',
        };

        await this.start(this._appConnection);
        await this.start(this._featureFlagsConnection, false);

        this._appConnection.connection.on('LanguageChanged', (data: string) => {
            this.store.dispatch(new fromFrame.LanguageChanged('SignalR Service', (<ILanguageChanged>JSON.parse(data)).data));
        });
        this._appConnection.connection.on('AppThemeChanged', (data: string) => {
            this.store.dispatch(new fromThemes.ThemeChanged('SignalR Service', (<ThemeChanged>JSON.parse(data)).data));
        });
        this._featureFlagsConnection.connection.on('FeatureFlagChanged', (data: string) => {
            this.store.dispatch(fromShared.FeatureStateChanged(<FeatureFlagChanged>JSON.parse(data)));
        });
    }

    async start(hubConnection: SignalRConnection, requiresAuthentication = true) {
        if (hubConnection !== undefined) {
            if (hubConnection.connection.state === signalR.HubConnectionState.Disconnected) {
                if (requiresAuthentication && this.authService.currentUserValue === null) {
                    hubConnection.connectionStatus = `${hubConnection.id} not logged in. Will connect after login.`;
                    console.log(hubConnection.connectionStatus);
                } else {
                    try {
                        hubConnection.connectionStatus = `Connecting ${hubConnection.id}...`;
                        console.log(hubConnection.connectionStatus);
                        await hubConnection.connection.start();
                        hubConnection.connectionStatus = `Connected ${hubConnection.id}`;
                        console.log(hubConnection.connectionStatus);
                    } catch (err) {
                        console.log(err);
                        hubConnection.connectionStatus = `Error connecting ${hubConnection.id}. Will reconnect in 3 seconds`;
                        console.log(hubConnection.connectionStatus);
                        setTimeout(() => this.start(hubConnection), 3000);
                    }
                }
            }
        }
    }

    async stop(hubConnection: SignalRConnection) {
        if (hubConnection && hubConnection.connection !== undefined) {
            if (hubConnection.connection.state === signalR.HubConnectionState.Connected) {
                hubConnection.connectionStatus = `Disconnecting ${hubConnection.id}...`;
                console.log(hubConnection.connectionStatus);
            }
            await hubConnection.connection.stop();
            hubConnection.connectionStatus = `Disconnected ${hubConnection.id}`;
            console.log(hubConnection.connectionStatus);
        }
    }

    subscribeOnClose(hubConnection: SignalRConnection) {
        hubConnection.connection.onclose(async () => {
            hubConnection.connectionStatus = 'Closed';
            console.log(hubConnection.connectionStatus);
            await this.start(hubConnection);
        });
    }
}

class SignalRConnection {
    id: string;
    connection: HubConnection | undefined;
    connectionStatus: string;
}

interface ILanguageChanged {
    name: string;
    timeStamp: Date;
    personId: number;
    data: ILanguageChangedData;
}

interface ILanguageChangedData {
    language: string;
}

interface ThemeChanged {
    name: string;
    timeStamp: Date;
    personId: number;
    data: Theme;
}

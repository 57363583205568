import { chain, concat, flatMap } from 'lodash';
import moment from 'moment';
import { HourDto, TimesheetStatementDto } from '../state/dtos';

export function calculateTotals(timesheetWeekRange: Date[], statements: TimesheetStatementDto[]): string[] {
    const hours = flatMap(statements, (s) => s?.hours).filter((s) => !!s);

    const totalHours = chain(
        concat(
            hours,
            timesheetWeekRange.map((d) => <HourDto>{ date: d })
        )
    )
        .orderBy((x) => moment(x.date).startOf('day'))
        .groupBy((h) => moment(h.date).startOf('day'))
        .map((value) => countTotals(value))
        .value();
    return totalHours;
}

export function calculateTotalOfTotals(statements: TimesheetStatementDto[]) {
    const hours = flatMap(statements, (s) => s?.hours);
    return countTotals(hours);
}

export function countHoursAsString(timeAsMinutes: number, prefixZero: boolean = false, zerosWhenEmpty: boolean = false): string {
    if (!timeAsMinutes) {
        return zerosWhenEmpty ? (prefixZero ? '00:00' : '0:00') : '';
    }
    const timeAsHours = Math.floor(timeAsMinutes / 60);
    const hours = prefixZero ? (timeAsHours < 10 ? '0' + timeAsHours : timeAsHours) : timeAsHours.toString();
    const minutes = timeAsMinutes % 60 < 10 ? '0' + (timeAsMinutes % 60) : timeAsMinutes % 60;
    return `${hours}:${minutes}`;
}

export function countTotals(hours: HourDto[]): string {
    const total = hours?.reduce((sum, el) => sum + calculateDuration(el), 0) || 0;
    const minutes = total % 60 < 10 ? '0' + (total % 60) : total % 60;
    return `${Math.floor(total / 60)}:${minutes}`;
}
export function calculateDuration(hour: HourDto): number {
    let duration = hour?.duration || 0;

    if (hour?.startTime && hour.startTime >= 0) {
        const start = hour.startTime || 0;
        const end = hour.endTime || 0;
        const breakTime = hour.breakTime || 0;

        duration = end - start - breakTime;

        if (hour.startTime && hour.endTime) {
            if (end <= start) {
                duration += 1440;
            }

            if (duration < 0) {
                duration = 0;
            }
        } else {
            duration = 0;
        }
    }
    return duration;
}

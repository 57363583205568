import { Component, Input } from '@angular/core';

@Component({
    selector: 'fp-error',
    templateUrl: './error.component.html'
})
export class ErrorComponent {
    @Input() error;
    @Input() translateParams;
    @Input() logoUrl;
}

import { orderBy } from 'lodash';
import { OrganisationStatements } from './../models/organisation-statements.model';
import { EmployeeTimesheetStatement, EmployeeTimesheetStatementHistory } from './../models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../state';

export interface EmployeeFacilitiesDashboardState {
    loading: boolean;
    statements: EmployeeTimesheetStatement[];
    organisationStatements: OrganisationStatements[];
    statementHistory: EmployeeTimesheetStatementHistory;
    selectedWeek: string;
}

export interface State extends fromRoot.State {
    employeeFacilities: EmployeeFacilitiesDashboardState;
}

export const EMPLOYEE_FACILITIES_FEATURE_KEY = 'employeeFacilitiesDashboard';
// Selectors
const getEmployeeFacilitiesDashboardFeatureState = createFeatureSelector<EmployeeFacilitiesDashboardState>(
    EMPLOYEE_FACILITIES_FEATURE_KEY
);
export const getStatements = createSelector(getEmployeeFacilitiesDashboardFeatureState, (state) => state.statements);
export const getOrganisationStatements = createSelector(getEmployeeFacilitiesDashboardFeatureState, (state) =>
    orderBy(state.organisationStatements, (os) => os.organisation.name)
);
export const getStatementVersions = createSelector(
    getEmployeeFacilitiesDashboardFeatureState,
    (state) => state.statementHistory
);

export const getLoading = createSelector(getEmployeeFacilitiesDashboardFeatureState, (state) => state.loading);

export const getSelectedWeek = createSelector(
    getEmployeeFacilitiesDashboardFeatureState,
    (state) => state.selectedWeek
);

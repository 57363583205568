import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromRoot from '../../state';
import { FooterSetting } from '../_models/footer-setting';

// Actions
export * from './frame.actions';

// State
export interface State extends fromRoot.State {
  frame: FrameState;
}

export interface FrameState {
  language: string;
  footerSettings: FooterSetting;
}

// Selectors
const getFrameFeatureState = createFeatureSelector<FrameState>('frame');

export const getLanguage = createSelector(
  getFrameFeatureState,
  state => state.language
);

export const getFooterSettings = createSelector(
  getFrameFeatureState,
  state => state.footerSettings
);

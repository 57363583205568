import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'fp-timesheet-comment',
    templateUrl: './timesheet-comment.component.html',
    styleUrls: ['./timesheet-comment.component.scss'],
})
export class TimesheetCommentComponent implements OnInit, AfterViewInit, OnDestroy {
    form: UntypedFormGroup;
    @Input() comment: string;
    @Input() readonly = false;
    @Output() commentChange = new EventEmitter<string>();

    constructor(private fb: UntypedFormBuilder) {}

    ngOnInit(): void {
        this.form = this.fb.group({
            comment: [this.comment, Validators.maxLength(180)],
        });
    }

    ngAfterViewInit(): void {
        this.form
            .get('comment')
            .valueChanges.pipe(debounceTime(1000))
            .subscribe(() => {
                this.commentChange.emit(this.commentValue);
            });
    }

    ngOnDestroy(): void {
        this.commentChange.emit(this.commentValue);
    }

    get commentValue(): string {
        return this.form?.get('comment')?.value;
    }
}

  <!-- The full screen button has been commented out by FH on 27-7-2020. -->
  <!-- It is only useful for the planning and until we implement that screen, the button only looks silly. -->
  <!-- <button class="btn btn-light btn-sm" type="button" (click)="toggleFullScreenOpen()">
    <i class="fal fa-expand-arrows"></i>
  </button> -->

<table>
  <tr>
    <td style="width: 30px;" *ngIf="backButtonCallback">
      <h3><a href="javascript:void(0)" (click)="backButtonCallback()">
        <i class="fas fa-chevron-left back-button"></i>
      </a></h3>
    </td>

    <td>
      <div class="truncate-ellipsis mb-2">
        <h3>{{ locationHeader.translationKey | translate:locationHeader.params }}</h3>
      </div>
    </td>

    <td style="width: 30px;">
      <h3 class="ml-2 mr-2"><a href="javascript:void(0)" (click)="toggleMenuOpen()">
        <i class="fas fa-bars"></i>
      </a></h3>  
    </td>
  </tr>
</table>

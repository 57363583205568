import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrganisationStatements } from '../../models/organisation-statements.model';

@Component({
    selector: 'fp-list-timesheet-statements',
    templateUrl: './list-timesheet-statements.component.html',
    styleUrls: ['./list-timesheet-statements.component.scss'],
})
export class ListTimesheetStatementsComponent implements OnInit {
    @Input() organisationStatements = [] as OrganisationStatements[];
    @Output() goToVersion = new EventEmitter<string>();

    constructor() {}

    ngOnInit(): void {}

    navigateToVersion(statementId: string): void {
        this.goToVersion.emit(statementId);
    }
}

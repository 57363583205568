<h5 style="font-weight: normal;" data-cy="userFullName" class="name">
    <span
        class="impersonated"
        *ngIf="impersonatedByFullName"
        (click)="onShowImpersonatedBy()"
        [title]="fullName + ' (via ' + impersonatedByFullName + ')'"
    >
        <i class="fad fa-people-arrows" style="width: 24px;"></i>

        <span class="full-name" *ngIf="!showImpersonatedBy">
            {{ fullName }}
        </span>
        <span class="full-name" *ngIf="showImpersonatedBy">
            Via: {{ impersonatedByFullName }}
        </span>
    </span>

    <span class="full-name" *ngIf="!impersonatedByFullName" [title]="fullName">
        {{ fullName }}
    </span>
</h5>
<a [routerLink]="['/auth/account-settings']" class="language">
    <i class="fal fa-comment mr-1" style="width: 24px;"></i
    >{{ 'auth.account-settings.languages.' + language | translate }}
</a>

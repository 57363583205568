import { EmployeeTimesheetFlexworker } from './../../../employee-facilities-dashboard/models/employee-timesheet-flexworker.model';
import { FlexworkerStatementWithOptions } from './../../../timesheets/models/flexworker-statements-group';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TimesheetFlexworkerDto } from 'src/app/timesheets/state/dtos';
import moment from 'moment';

@Component({
    selector: 'fp-flexworker-info',
    templateUrl: './flexworker-info.component.html',
    styleUrls: ['./flexworker-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexworkerInfoComponent implements OnInit {
    @Input() statement = {} as FlexworkerStatementWithOptions;
    @Input() flexworker = {} as TimesheetFlexworkerDto | EmployeeTimesheetFlexworker;
    @Input() showShift = true;
    @Input() showJobTitle = true;
    @Input() showCostCenter = true;
    @Input() showFullNameReversed = false;
    @Input() showLargeFullName = false;
    @Input('showWorkingDays') set setShowWorkingDays(value: boolean) {
        const isFullWeek =
            moment(this.statement.firstDay).isoWeekday() === 1 && moment(this.statement.lastDay).isoWeekday() < 7;
        this.showWorkingDays = value && isFullWeek;
    }
    @Input() maxWidthPx: string = '200px'; //default width for timesheet statements table

    showWorkingDays = false;

    constructor() {}

    ngOnInit(): void {}

    getFlexworkerProperty(keys: string[]) {
        return keys.reduce((value, key) => value[key], this.flexworker);
    }
}

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AbsencesApiService } from './api/absencesApi.service';
import { AccountSettingsApiService } from './api/accountSettingsApi.service';
import { AppThemesApiService } from './api/appThemesApi.service';
import { AuthenticationApiService } from './api/authenticationApi.service';
import { ContactsApiService } from './api/contactsApi.service';
import { ExamplePayrollApiApiService } from './api/examplePayrollApiApi.service';
import { FeatureFlagsApiService } from './api/featureFlagsApi.service';
import { FlexworkerPortalApiService } from './api/flexworkerPortalApi.service';
import { FlexworkersApiService } from './api/flexworkersApi.service';
import { GeneralSettingsApiService } from './api/generalSettingsApi.service';
import { HousingPlanningApiService } from './api/housingPlanningApi.service';
import { ImportActionsApiService } from './api/importActionsApi.service';
import { InsuranceDocumentsApiService } from './api/insuranceDocumentsApi.service';
import { InternetPortalSettingsApiService } from './api/internetPortalSettingsApi.service';
import { InternetPortalsApiService } from './api/internetPortalsApi.service';
import { JobSeekerRegistrationApiService } from './api/jobSeekerRegistrationApi.service';
import { JobSeekerRegistrationPublicApiService } from './api/jobSeekerRegistrationPublicApi.service';
import { JourneysApiService } from './api/journeysApi.service';
import { OrganisationPortalApiService } from './api/organisationPortalApi.service';
import { OrganisationsApiService } from './api/organisationsApi.service';
import { OrganisationsPayslipsApiService } from './api/organisationsPayslipsApi.service';
import { PageMenuTabsApiService } from './api/pageMenuTabsApi.service';
import { PersonnelNumbersApiService } from './api/personnelNumbersApi.service';
import { RequestsApiService } from './api/requestsApi.service';
import { ShiftsApiService } from './api/shiftsApi.service';
import { SignedDocumentsApiService } from './api/signedDocumentsApi.service';
import { StatementStatusesApiService } from './api/statementStatusesApi.service';
import { TenantsApiService } from './api/tenantsApi.service';
import { TimeTriggersApiService } from './api/timeTriggersApi.service';
import { TimesheetsApiService } from './api/timesheetsApi.service';
import { TimesheetsDashboardApiService } from './api/timesheetsDashboardApi.service';
import { TravelStatementsApiService } from './api/travelStatementsApi.service';
import { UnravelResultsApiService } from './api/unravelResultsApi.service';
import { UnravelSchemesApiService } from './api/unravelSchemesApi.service';
import { UnravelerApiService } from './api/unravelerApi.service';
import { WebManifestApiService } from './api/webManifestApi.service';
import { WorkPlanningApiService } from './api/workPlanningApi.service';
import { WorksheetsApiService } from './api/worksheetsApi.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { find, orderBy } from 'lodash';
import moment from 'moment';
import { getWeek } from 'src/app/timesheets/functions/get-week';
import { countHoursAsString, countTotals } from 'src/app/timesheets/functions/hours-calculations';
import { TimesheetMode } from 'src/app/timesheets/state/dtos';
import { HourDto } from './../../../timesheets/state/dtos';

interface ReadonlyHour {
    date?: Date;
    startTime?: string;
    endTime?: string;
    breakTime?: string;
    duration?: string;
}

@Component({
    selector: 'fp-readonly-hours-row',
    templateUrl: './readonly-hours-row.component.html',
    styleUrls: ['./readonly-hours-row.component.scss'],
})
export class ReadonlyHoursRowComponent implements OnInit {
    @Input() timesheetMode = TimesheetMode.StartEndBreakTime;
    @Input('hours') set setHours(hours: HourDto[]) {
        const startOfWeek = moment(hours[0].date).startOf('isoWeek');
        const endOfWeek = moment(hours[0].date).endOf('isoWeek');
        this.currentWeekDaysRange = getWeek(startOfWeek, endOfWeek);
        this.total = countTotals(hours);
        this.createDurations(hours);
    }

    hours = [] as ReadonlyHour[];
    timesheetModeValues = TimesheetMode;
    currentWeekDaysRange = [] as Date[];
    total: string;

    constructor() {}

    ngOnInit(): void {}

    private createDurations(hours: HourDto[]) {
        this.hours = orderBy(
            this.currentWeekDaysRange.map((d) => {
                const hour = find(hours, (h) => moment(h.date).diff(d, 'days') === 0);
                return {
                    date: d,
                    startTime: countHoursAsString(hour?.startTime),
                    endTime: countHoursAsString(hour?.endTime),
                    breakTime: countHoursAsString(hour?.breakTime),
                    duration: hour?.duration != null ? countTotals([hour]) : '',
                } as ReadonlyHour;
            }),
            (x) => x.date
        );
    }
}

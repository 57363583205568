import { AttachmentDto } from './../../../rest/model/attachmentDto';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'fp-timesheet-attachments',
    templateUrl: 'attachments.component.html',
    styleUrls: ['attachments.component.scss'],
})
export class TimesheetAttachmentsComponent {
    @Input() attachments: AttachmentDto[];
    @Input() readonly = false;

    @Output() uploadClick = new EventEmitter();
    @Output() download = new EventEmitter<number>();
    @Output() delete = new EventEmitter<number | string>();

    constructor() {}

    onFileSelected() {
        this.uploadClick.emit();
    }

    onFileDelete(attachment: AttachmentDto) {
        this.delete?.emit(attachment.id || attachment.fileName);
    }

    onFileDownload(attachment: AttachmentDto) {
        this.download?.emit(attachment.id);
    }
}

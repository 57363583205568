import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authService.currentUserValue;
        if (currentUser) {
            // check if route is restricted by permission
            const isAllowedByPermissions =
                !route.data.permissions || route.data.permissions.filter((value) => currentUser.permissions.includes(value)).length > 0;

            // check if route is restricted by role
            const isAllowedByRole = !route.data.roles || route.data.roles.filter((value) => currentUser.role === value).length > 0;

            if (!isAllowedByPermissions || !isAllowedByRole) {
                console.log(`You are not authorized to view ${state.url}`);
                this.router.navigate(['/no-access'], {
                    queryParams: {
                        access: 'no_access_to_page',
                    },
                });
                return false;
            }

            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}

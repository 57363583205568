import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GeneralSetting } from '../../auth/_models/general-setting';
import { FeatureDto } from '../../rest';
import { FeatureInfo } from '../../settings/models/featureInfo';
import * as fromRoot from '../../state';
import { TenantFeatureDto } from './../../rest/model/tenantFeatureDto';

// Actions
export * from './shared.actions';

// State
export interface State extends fromRoot.State {
    shared: SharedState;
}

export interface SharedState {
    generalSetting: GeneralSetting;
    generalSettingsSaving: boolean;
    featuresLoading: boolean;
    tenantFeaturesLoading: boolean;
    currentFeatureLoading: boolean;
    features: FeatureDto[];
    tenantFeatures: TenantFeatureDto[];
    currentFeature: FeatureInfo;
}

// Selectors
const getSharedFeatureState = createFeatureSelector<SharedState>('shared');

export const getGeneralSetting = createSelector(getSharedFeatureState, (state) => state.generalSetting);
export const getGeneralSettingsSaving = createSelector(getSharedFeatureState, (state) => state.generalSettingsSaving);
export const getFeatures = createSelector(getSharedFeatureState, (state) => state.features);
export const getTenantFeatures = createSelector(getSharedFeatureState, (state) => state.tenantFeatures);
export const getFeaturesLoading = createSelector(getSharedFeatureState, (state) => state.featuresLoading);
export const getTenantFeaturesLoading = createSelector(getSharedFeatureState, (state) => state.tenantFeaturesLoading);
export const getCurrentFeature = createSelector(getSharedFeatureState, (state) => state.currentFeature);
export const getCurrentFeatureFlags = createSelector(getCurrentFeature, (feature) => feature?.flags);
export const getCurrentFeatureLoading = createSelector(getSharedFeatureState, (state) => state.currentFeatureLoading);

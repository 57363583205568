import { FooterComponent } from './components/footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { CurrentUserComponent } from './components/current-user/current-user.component';
import { NavLocationComponent } from './nav-location/nav-location.component';
import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { FrameComponent } from './frame/frame.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { frameReducer } from './state//frame.reducer';
import { FrameEffects } from './state/frame.effects';
import { CurrentUserShellComponent } from './containers/current-user-shell/current-user-shell.component';
import { ThemesModule } from '../themes/themes.module';
import { FooterShellComponent } from './containers/footer-shell/footer-shell.component';

@NgModule({
    declarations: [
        FooterComponent,
        FooterShellComponent,
        MenuComponent,
        CurrentUserComponent,
        CurrentUserShellComponent,
        NavLocationComponent,
        FrameComponent
    ],
    imports: [
        SharedModule,
        ThemesModule,
        StoreModule.forFeature('frame', frameReducer),
        EffectsModule.forFeature([FrameEffects]),
    ],
    exports: [
        FooterShellComponent,
        MenuComponent,
        CurrentUserComponent,
        NavLocationComponent,
        FrameComponent,
    ],
})
export class FrameModule { }

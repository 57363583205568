import { Injectable } from '@angular/core';
import { ThemeStyleSheetService } from '../../frame/_services/theme-style-sheet-service';
import { Theme } from '../../frame/state/dtos/theme.dto';
import { Store } from '@ngrx/store';
import * as fromTheme from 'src/app/themes/state';
import { theme } from '../../../client-assets/config';

@Injectable({
    providedIn: 'root'
})
export class ClientConfigService {
    theme: Theme;

    constructor(
        private themeStyleSheetService: ThemeStyleSheetService,
        private themeStore: Store<fromTheme.ThemesState>
    ) { }

    load() {
        this.theme = theme as Theme;
        this.themeStyleSheetService.updateAppThemeStyleSheet(this.theme);

        this.themeStore.dispatch(new fromTheme.ThemeChanged('', this.theme));
    }
}

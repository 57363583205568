import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromApp from '.';
import * as fromFrame from '../frame/state';
import { PageMenuTabsApiService } from '../rest/api/pageMenuTabsApi.service';
import { PageMenuTabDto } from './../rest/model/pageMenuTabDto';
import { OriginAction } from './payload.interfaces';

@Injectable()
export class AppEffects {
    constructor(private actions$: Actions, private pageMenuTabsApiService: PageMenuTabsApiService) {}

    
    init$ = createEffect(() => this.actions$.pipe(
        ofType(fromApp.AppActionTypes.Init),
        map((action) => <OriginAction>action),
        map((action) => {
            // Get the last used language from the local storage. Use that or default to Dutch.
            const language = localStorage.getItem('language');
            return new fromFrame.LanguageChanged(action.origin + ' > Frame Effects', {
                language: language === null ? 'nl' : language,
            });
        })
    ));

    
    loadPageMenuTabs$ = createEffect(() => this.actions$.pipe(
        ofType(fromApp.AppActionTypes.LoadPageMenuTabs),
        switchMap((action: fromApp.LoadPageMenuTabs) => {
            return this.pageMenuTabsApiService.apiPageMenuTabsGet().pipe(
                map((data: PageMenuTabDto[]) => {
                    return new fromApp.LoadPageMenuTabsSuccess(this.newOrigin(action.origin), data);
                }),
                catchError(() => of(new fromApp.LoadPageMenuTabsFailed(this.newOrigin(action.origin))))
            );
        })
    ));

    newOrigin(prevOrigin: string): string {
        return prevOrigin + ' > Frame Effects';
    }
}

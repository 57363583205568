import { Inject, Injectable } from '@angular/core';
import { Theme } from '../state/dtos/theme.dto';
import { Favicon } from 'src/app/rest/model/favicon';
import { BASE_PATH } from 'src/app/rest';

@Injectable({
    providedIn: 'root',
})
export class ThemeStyleSheetService {
    styleSheet: CSSStyleSheet;

    constructor(@Inject(BASE_PATH) private basePath) {
        // Create style sheet for the app theme.
        const style = document.createElement('style');
        style.appendChild(document.createTextNode(''));
        document.head.appendChild(style);
        this.styleSheet = <CSSStyleSheet>style.sheet;
    }

    updateAppThemeStyleSheet(theme: Theme) {
        // Delete all rules.
        while (this.styleSheet.rules.length > 0) {
            this.styleSheet.deleteRule(0);
        }

        // Set new rules.
        this.insertRule('.accent-color', 'color', theme.accentColor);
        this.insertRule('.accent-color-background', 'background-color', theme.accentColor);
        this.insertRule('.accent-color-background-25', 'background-color', theme.accentColor + '40');
        this.insertRule('.accent-color-border', 'border-color', theme.accentColor);
        this.insertRule('.header-logo', 'background-color', theme.headerColor);
        this.insertRule('.hl', 'background-color', theme.headerColor);
        this.insertRule('.hc', 'background-color', theme.headerColor);
        this.insertRule('.hr', 'background-color', theme.headerColor);

        this.insertRule('a', 'color', theme.accentColor);
        this.insertRule('a:active', 'color', theme.accentColor);
        this.insertRule('a:hover', 'color', theme.accentColor);

        this.insertRule('.btn-link', 'color', theme.accentColor);
        this.insertRule('.btn-link:hover', 'color', theme.accentColor);
        this.insertRule('.switch.checked', 'color', theme.accentColor);
        this.insertRule('.switch.checked', 'background-color', theme.accentColor);

        this.insertRule('.save-right-arrow', 'color', theme.accentColor);
        this.insertRule('.btn-primary', 'background-color', theme.accentColor);
        this.insertRule('.btn-primary', 'border-color', theme.accentColor);
        this.insertRule(
            `.btn-primary:not(:disabled):not(.disabled):active,
      .btn-primary:not(:disabled):not(.disabled).active,
      .show > .btn-primary.dropdown-toggle`,
            'background-color',
            theme.accentColor
        );
        this.insertRule(
            `.btn-primary:not(:disabled):not(.disabled):active,
      .btn-primary:not(:disabled):not(.disabled).active,
      .show > .btn-primary.dropdown-toggle`,
            'border-color',
            theme.accentColor
        );
        this.insertRule('.btn-primary:hover', 'background-color', theme.accentColor);
        this.insertRule('.btn-primary:hover', 'border-color', theme.accentColor);

        this.updateFavicon(theme.favicons);
    }

    insertRule(selector: string, name: string, value: string) {
        this.styleSheet.insertRule(`${selector} {
      ${name}: ${value};
    }`);
    }

    updateFavicon(favicons: Favicon[]) {
        if (this.getIconBySize(favicons, 16)) { this.setFaviconAttr("16") }
        if (this.getIconBySize(favicons, 32)) { this.setFaviconAttr("32") }
        if (this.getIconBySize(favicons, 180)) { this.setFaviconAttr("180") }
    }
    getIconBySize(favicons: Favicon[], size: number): Favicon {
        return favicons && favicons.find((i) => i.iconHeight === size);
    }
    setFaviconAttr(size: string) {
        document
            .getElementById('icon-' + size)
            .setAttribute('href', this.basePath + "/api/appthemes/favicons/" + size);
    }
}

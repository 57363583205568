export const environment = {
    production: true,
    BASE_PATH: 'This variable is replaced', // by a function of the window.location.hostname in the app.module.ts
    ELMAH_API_KEY: 'dc59308b8a764e62a16b79c9ad8c4c77',
    ELMAH_LOG_ID: 'fd030750-bde7-407d-b8a0-2a1ddda746d0',
    FEATURE_FLAGS_PATH: 'https://app-feature-flags.azurewebsites.net',
    appInsights: {
        instrumentationKey: '4cfaa46d-b6e7-4dee-9668-607403c2123f',
    },
    releaseDate: '##{ReleaseDate}##',
};

import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
    templateUrl: './confirm-delete.component.html'
})
export class ConfirmDeleteComponent implements OnInit {
    public onClose: Subject<boolean>;

    constructor(public bsModalRef: BsModalRef) { }

    public ngOnInit(): void {
        this.onClose = new Subject();
    }

    action(value: boolean) {
        this.bsModalRef.hide();
        this.onClose.next(value);
        this.onClose.complete();
    }
}

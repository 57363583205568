<form novalidate (ngSubmit)="onSave()" [formGroup]="form" *ngIf="!loading" class="theme-container">
    <div class="form-group row favicon-row align-items-center">
        <span class="name">
            {{ 'settings.app-themes.favicon' | translate }}
        </span>
        <div class="favicon-uploader">
            <fp-image-uploader
                [width]="24"
                [height]="24"
                [isSmall]="true"
                [imageObjectPosition]="'center'"
                formControlName="faviconUrl"
                (imageChange)="onFaviconChange($event)"
            >
            </fp-image-uploader>
        </div>
        <button
            type="button"
            class="btn btn-primary ml-4"
            [disabled]="savingFavicon"
            *ngIf="!form.get('faviconUrl').pristine || savingFavicon"
            (click)="onSaveFavicon()"
        >
            {{ 'shared.save' | translate }}
            <i *ngIf="savingFavicon" class="fas fa-spinner fa-spin ml-2"></i>
        </button>
    </div>

    <div class="themes" formArrayName="themes">
        <div class="row header align-items-center">
            <div class="name"></div>
            <div class="logo">
                {{ 'settings.app-themes.logo' | translate }}
            </div>
            <div class="header-color">
                {{ 'settings.app-themes.header-color' | translate }}
            </div>
            <div class="accent-color">
                {{ 'settings.app-themes.accent-color' | translate }}
            </div>
        </div>
        <ng-container *ngFor="let item of themesArray.controls; let i = index">
            <div class="row align-items-center" [formGroupName]="i">
                <span class="name" [innerText]="item.get('name').value | translate"></span>
                <ng-container *ngIf="item.get('hasTheme').value">
                    <div class="logo logo-uploader">
                        <fp-image-uploader
                            [maxWidth]="200"
                            [maxHeight]="70"
                            [formControl]="item.controls['logoUrl']"
                            (imageChange)="onEditTheme(i)"
                        >
                        </fp-image-uploader>
                    </div>
                    <div class="header-color">
                        <input
                            type="color"
                            width="30px"
                            class="form-control"
                            (change)="onEditTheme(i)"
                            [formControl]="item.controls['headerColor']"
                        />
                    </div>
                    <div class="accent-color">
                        <input type="color" class="form-control" [formControl]="item.controls['accentColor']" (change)="onEditTheme(i)" />
                    </div>
                </ng-container>

                <span class="no-theme" *ngIf="!item.get('hasTheme').value && !item.get('isSaving').value">{{
                    'settings.app-themes.no-theme' | translate
                }}</span>

                <div class="action-buttons">
                    <button
                        type="button"
                        class="btn btn-primary save"
                        [disabled]="item.get('isSaving').value"
                        [hidden]="!item.get('hasTheme').value"
                        [disabled]="!item.valid || item.get('isSaving').value"
                        (click)="onSaveTheme(i)"
                    >
                        <i class="icon fas fa-save" [ngClass]="{ hidden: item.get('isSaving').value }"></i>
                        <i *ngIf="item.get('isSaving').value" class="fas fa-spinner fa-spin"></i>
                    </button>
                </div>

                <div class="action-buttons">
                    <button
                        type="button"
                        class="btn btn-primary"
                        [disabled]="saving"
                        *ngIf="!item.get('hasTheme').value"
                        (click)="onAddTheme(i)"
                    >
                        <i class="icon fas fa-plus-circle"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        [disabled]="saving"
                        *ngIf="item.get('hasTheme').value && !item.get('isGeneral').value"
                        (click)="onDeleteTheme(i)"
                    >
                        <i class="icon fas fa-minus-circle"></i>
                    </button>
                </div>
            </div>
            <hr *ngIf="item.get('isGeneral').value" />
        </ng-container>
    </div>
</form>

<div class="timesheet-attachments-container" [class.readonly-attachments]="readonly">
    <label class="field-label">{{ 'shared.attachments' | translate }}</label>
    <div class="files">
        <ng-container *ngIf="readonly; else default">
            <span class="readonly-attachment" *ngFor="let attachment of attachments">
                {{ attachment.fileName }}</span
            >
        </ng-container>
        <ng-template #default>
            <div class="documents-container">
                <fp-file-attachment
                    *ngFor="let attachment of attachments"
                    [fileName]="attachment.fileName"
                    [downloadAvailable]="attachment.id"
                    (delete)="onFileDelete(attachment)"
                    (download)="onFileDownload(attachment)"
                ></fp-file-attachment>
            </div>
            <fp-spinner-button
                [buttonClasses]="'btn btn-secondary mt-2'"
                [text]="'timesheets.upload-attachments' | translate"
                (click)="onFileSelected()"
            >
            </fp-spinner-button>
        </ng-template>
    </div>
</div>

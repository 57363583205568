import { isEmpty, sortBy } from 'lodash';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { EMPTY, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EmployeeTimesheetStatementHistory, EmployeeTimesheetStatementVersion } from '../../models';
import * as fromStore from '../../state';
import { EmployeeFacilitiesDashboardState } from '../../state';
import { LoadStatementVersion } from '../../state/employee-facilities-dashboard.actions';
import { FlexworkerStatementWithOptions } from './../../../timesheets/models/flexworker-statements-group';
import { EmployeeTimesheetStatementVersionDifference } from './../../models/employee-timesheet-statement-version-difference.model';

@Component({
    selector: 'fp-list-versions-shell',
    templateUrl: './list-versions-shell.component.html',
    styleUrls: ['./list-versions-shell.component.scss'],
})
export class ListVersionsShellComponent implements OnInit, AfterViewInit {
    timesheetStatementHistory: EmployeeTimesheetStatementHistory;
    versions: EmployeeTimesheetStatementVersionDifference[] = [];
    statement: FlexworkerStatementWithOptions;
    menuItemId: number;

    isLoading$: Observable<boolean>;

    constructor(
        private store: Store<EmployeeFacilitiesDashboardState>,
        private route: ActivatedRoute,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.menuItemId = this.route.snapshot.params['menuItemId'];
        this.store.dispatch(LoadStatementVersion({ statementId: this.route.snapshot.params['id'] }));

        this.isLoading$ = this.store.select(fromStore.getLoading);
    }

    ngAfterViewInit(): void {
        this.store
            .select(fromStore.getStatementVersions)
            .pipe(
                switchMap((timesheetStatementHistory) => {
                    if (!timesheetStatementHistory) {
                        return EMPTY;
                    }
                    this.timesheetStatementHistory = timesheetStatementHistory;
                    this.versions = [];
                    this.handleVersionsHourseDifference(this.timesheetStatementHistory.versions);
                    this.statement = {
                        jobTitle: timesheetStatementHistory.jobTitle,
                        costCenter: timesheetStatementHistory.costCenter,
                    };
                    return EMPTY;
                })
            )
            .subscribe();
    }

    private async handleVersionsHourseDifference(versions: EmployeeTimesheetStatementVersion[]): Promise<void> {
        const HOUR_IN_MILISECONDS = 60000;
        let sortedVersions = [];
        if (versions?.length) {
            sortedVersions = versions.slice().sort((v1, v2) => moment(v2.changedOn).diff(v1.changedOn));
        }
        (await sortedVersions?.map(async (version, i) => {
            if (
                moment(version?.changedOn).seconds(0).milliseconds(0).toISOString() ===
                moment(versions[i + 1]?.changedOn)
                    .seconds(0)
                    .milliseconds(0)
                    .toISOString()
            ) {
                this.versions.push({
                    version,
                    difference: null,
                });
                return;
            }
            if (version?.changedOn && versions[i + 1]?.changedOn) {
                const intMinutesDifference = Math.abs(
                    moment(version.changedOn).diff(sortedVersions[i + 1].changedOn) / HOUR_IN_MILISECONDS
                );
                if (!+intMinutesDifference.toFixed(0)) {
                    this.versions.push({
                        version,
                        difference: null,
                    });
                    return;
                }
                const result = await this.convertMinutesToDifference(intMinutesDifference).then((difference) => ({
                    version,
                    difference,
                }));
                this.versions.push(result);
            } else {
                this.versions.push({
                    version,
                    difference: null,
                });
            }
            return;
        })) || [];
    }

    private convertMinutesToDifference(minutes: number): Promise<string> {
        let result = '';
        let daysDifference: number | string = '';
        let hoursDifference: number | string;
        let minutesDifference: number | string;
        if (minutes > 1440) {
            daysDifference = (minutes / 1440).toFixed(0);
            minutes = minutes % 1440;
        }
        hoursDifference = minutes / 60;
        minutesDifference = minutes % 60;
        [hoursDifference, minutesDifference] = [hoursDifference, minutesDifference].map((el, index) => {
            if (!el) {
                return '00';
            } else if (el < 10 && index === 1) {
                return `0${el.toFixed(0)}`;
            } else {
                return el.toFixed(0);
            }
        });
        return this.translateService
            .get(['shared.days', 'shared.hours', 'shared.later', 'shared.day', 'shared.and'])
            .pipe(
                switchMap((t) => {
                    const daysPart = `${daysDifference} ${
                        daysDifference ? t[this.getDayTranslation(+daysDifference)] : ''
                    }`;
                    const hoursPart =
                        (!hoursDifference && !minutesDifference) ||
                        (hoursDifference === '0' && minutesDifference === '00')
                            ? ''
                            : `${hoursDifference}:${minutesDifference} ${t['shared.hours']?.toLowerCase()}`.trim();
                    result = `${daysPart} ${!!daysDifference ? t['shared.and'] : ''} ${hoursPart}`.trim();
                    return of(!!result && `(${result} ${t['shared.later']}) `.trim());
                })
            )
            .toPromise();
    }

    getDayTranslation(daysDifference: number): string {
        if (daysDifference > 1) {
            return 'shared.days';
        } else if (daysDifference > 0 && daysDifference <= 1) {
            return 'shared.day';
        } else {
            return '';
        }
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthGuard } from '../auth/_guards';
import { SharedModule } from './../shared/shared.module';
import { ListTimesheetStatementsComponent } from './components/list-timesheet-statements/list-timesheet-statements.component';
import { PeriodSelectionComponent } from './components/period-selection/period-selection.component';
import { ReadonlyCompensationComponent } from './components/readonly-compensation/readonly-compensation.component';
import { ReadonlyHoursRowComponent } from './components/readonly-hours-row/readonly-hours-row.component';
import { StatementVersionComponent } from './components/statement-version/statement-version.component';
import { ListVersionsShellComponent } from './containers/list-versions-shell/list-versions-shell.component';
import { TimesheetDashboardShellComponent } from './containers/timesheet-dashboard-shell/timesheet-dashboard-shell.component';
import { EmployeeFacilitiesDashboardEffects } from './state/employee-facilities-dashboard.effects';
import { reducer } from './state/employee-facilities-dashboard.reducer';
import { EMPLOYEE_FACILITIES_FEATURE_KEY } from './state/index';

@NgModule({
    declarations: [
        StatementVersionComponent,
        ReadonlyHoursRowComponent,
        ReadonlyCompensationComponent,
        PeriodSelectionComponent,
        ListVersionsShellComponent,
        ListTimesheetStatementsComponent,
        TimesheetDashboardShellComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        StoreModule.forFeature(EMPLOYEE_FACILITIES_FEATURE_KEY, reducer),
        EffectsModule.forFeature([EmployeeFacilitiesDashboardEffects]),
        RouterModule.forChild([
            {
                path: 'dashboard/:menuItemId',
                component: TimesheetDashboardShellComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'versions/:menuItemId/:id',
                component: ListVersionsShellComponent,
                canActivate: [AuthGuard],
            },
        ]),
    ],
})
export class EmployeeFacilitiesDashboardModule {}

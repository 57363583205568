import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, map, catchError, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ILanguage, PayloadAction } from 'src/app/state/payload.interfaces';
import * as moment from 'moment';

import * as fromFrame from '.';
import { of } from 'rxjs';
import { FooterSetting } from '../_models/footer-setting';
import { GeneralSettingsApiService } from 'src/app/rest';

@Injectable()
export class FrameEffects {
    constructor(
        private actions$: Actions,
        private generalSettingsApiService: GeneralSettingsApiService,
        private translate: TranslateService
    ) {}

    
    languageChanged$ = createEffect(() => this.actions$.pipe(
        ofType(fromFrame.FrameActionTypes.LanguageChanged),
        map((action) => <PayloadAction<ILanguage>>action),
        tap((action) => {
            // Change the language used for the UI translation.
            const language = action.payload.language;
            this.translate.use(language);
            moment.locale(language);

            // When the user logs out, the login page will be shown in the last set language.
            localStorage.setItem('language', language);
        })
    ), { dispatch: false });

    
    loadGeneralSettings$ = createEffect(() => this.actions$.pipe(
        ofType(fromFrame.FrameActionTypes.LoadFooterSettings),
        mergeMap((action: fromFrame.LoadFooterSettings) => {
            return this.generalSettingsApiService.apiGeneralSettingsGetFooterSettingsGet().pipe(
                map((data) => {
                    document.title = data.company;
                    return new fromFrame.LoadFooterSettingsSuccess(this.newOrigin(action.origin), data as FooterSetting);
                }),
                catchError(() => of(new fromFrame.LoadFooterSettingsFailed(this.newOrigin(action.origin))))
            );
        })
    ));

    newOrigin(prevOrigin: string): string {
        return prevOrigin + ' > Shared Effects';
    }
}

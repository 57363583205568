import { Component, Input, EventEmitter, Output, NgZone } from '@angular/core';
import { Observable, Subject, BehaviorSubject, interval } from 'rxjs';
import { map, delay, tap } from 'rxjs/operators';

@Component({
    selector: 'fp-spinner-button',
    templateUrl: 'spinner-button.component.html',
})
export class SpinnerButtonComponent {
    @Input() public text = '';
    @Input() public spinnerDelay = 10;
    @Input() public buttonClasses = 'btn btn-primary mr-2';
    @Input() public height = '42px';
    private _waiting: boolean;

    @Input() set waiting(value: boolean) {
        this._waiting = value;
        this.showSpinnerDelayedSubject.next(value);
    }

    get waiting() {
        return this._waiting;
    }

    @Input()
    public disabled = false;

    public showSpinnerDelayedSubject: BehaviorSubject<boolean>;
    public showSpinnerDelayed$: Observable<boolean>;

    @Output() public clicked = new EventEmitter();

    constructor() {
        this.showSpinnerDelayedSubject = new BehaviorSubject<boolean>(false);
        this.showSpinnerDelayed$ = this.showSpinnerDelayedSubject.asObservable().pipe(
            delay(this.spinnerDelay),
            map((value) => this._waiting && value)
        );
    }

    onClick() {
        this.clicked.emit();
    }
}

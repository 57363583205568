<div class="flexworker-info" [style.maxWidth]="maxWidthPx">
    <div class="name-and-male">
        <ng-container [ngSwitch]="flexworker?.gender">
            <i *ngSwitchCase="'0'" class="fal fa-venus"></i>
            <i *ngSwitchCase="'1'" class="fal fa-mars"></i>
        </ng-container>
        &nbsp;
        <flag-icon *ngIf="flexworker?.nationality" [country]="flexworker?.nationality | lowercase"></flag-icon>
        <span class="title" [class.large-title]="showLargeFullName"
            >&nbsp;&nbsp;{{
                showFullNameReversed ? getFlexworkerProperty(['fullNameReversed']) : flexworker?.fullName
            }}</span
        >
    </div>
    <div class="statement-info">
        <div class="role" *ngIf="statement.jobTitle && showJobTitle">
            <i class="fal fa-credit-card-front"></i>&nbsp;{{ statement.jobTitle }}
        </div>
        <div class="company" *ngIf="statement.costCenter?.name && showCostCenter">
            <i class="fal fa-industry-alt"></i>&nbsp;{{ statement.costCenter?.name || '?' }}
        </div>
        <!-- <div class="part-of-day" *ngIf="showShift && data.shift"><i class="fal fa-eclipse"></i>&nbsp;{{ data.shift }}</div> -->
        <div class="working-days" *ngIf="showWorkingDays">
            <i class="fal fa-calendar-week"></i>&nbsp;{{ statement.firstDay | date: 'EEEE' }} -
            {{ statement.lastDay | date: 'EEEE' }}
        </div>
    </div>
</div>

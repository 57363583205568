import { Component, Input } from '@angular/core';

@Component({
    selector: 'fp-footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
    @Input() name: string;
    @Input() internetPortalName: string;
    @Input() organisationName: string;
    @Input() telephone: string;
    @Input() email: string;
}

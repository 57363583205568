import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../state';
import { Theme, ThemeSpecifications } from './themes.actions';

// Actions
export * from './themes.actions';

// State
export interface State extends fromRoot.State {
  themes: ThemesState;
}

export interface ThemesState {
  theme: Theme;
  loadingTheme: boolean;
  themeSpecifications: ThemeSpecifications;
  loadingThemeSpecifications: boolean;
  savingThemeSpecifications: boolean;
  savingFavicon: boolean;
}

// Selectors
const getThemesFeatureState = createFeatureSelector<ThemesState>('themes');

export const getTheme = createSelector(
  getThemesFeatureState,
  state => state.theme
);
export const getlogoUrl = createSelector(
  getThemesFeatureState,
  state => state.theme.logoUrl
);
export const getLoading = createSelector(
  getThemesFeatureState,
  state => state.loadingTheme
);
export const getThemeSpecifications = createSelector(
  getThemesFeatureState,
  state => state.themeSpecifications
);
export const getLoadingThemeSpecifications = createSelector(
  getThemesFeatureState,
  state => state.loadingThemeSpecifications
);
export const getSavingThemeSpecifications = createSelector(
  getThemesFeatureState,
  state => state.savingThemeSpecifications
);
export const getSavingFavicon = createSelector(
  getThemesFeatureState,
  state => state.savingFavicon
);

import { FrameActions, FrameActionTypes } from './frame.actions';
import { FrameState } from '.';

export const initialState: FrameState = {
  language: 'nl',
  footerSettings: null
};

export function frameReducer(state = initialState, action: FrameActions): FrameState {
  switch (action.type) {
    case FrameActionTypes.LanguageChanged:
      return {
        ...state,
        language: action.payload.language
      };

    case FrameActionTypes.LoadFooterSettings:
      return {
        ...state,
        footerSettings: null
      };
    case FrameActionTypes.LoadFooterSettingsSuccess:
      return {
        ...state,
        footerSettings: action.payload,
      };
    case FrameActionTypes.LoadFooterSettingsFailed:
      return {
        ...state,
        footerSettings: null
      };

    default:
      return state;
  }
}

import { PageMenuTabDto } from './../rest/model/pageMenuTabDto';
import { OriginAction, PayloadAction } from './payload.interfaces';

export enum AppActionTypes {
    Init = '[App] Init',
    LoadPageMenuTabs = '[App] Load Page Menu Tabs',
    LoadPageMenuTabsSuccess = '[App] Load Page Menu Tabs Success',
    LoadPageMenuTabsFailed = '[App] Load Page Menu Tabs Failed',
}

export class Init implements OriginAction {
    readonly origin = 'App Component';
    readonly type = AppActionTypes.Init;
}

export class LoadPageMenuTabs implements OriginAction {
    readonly origin = 'App Component';
    readonly type = AppActionTypes.LoadPageMenuTabs;
}

export class LoadPageMenuTabsSuccess implements PayloadAction<PageMenuTabDto[]> {
    readonly type = AppActionTypes.LoadPageMenuTabsSuccess;
    constructor(public origin: string, public payload: PageMenuTabDto[]) {}
}
export class LoadPageMenuTabsFailed implements OriginAction {
    readonly type = AppActionTypes.LoadPageMenuTabsFailed;
    constructor(public origin: string) {}
}

export type AppActions = Init | LoadPageMenuTabs | LoadPageMenuTabsSuccess | LoadPageMenuTabsFailed;

<div class="filters">
    <fp-period-selection
        [weeksOptions]="weeksOptions"
        [selectedWeek]="selectedWeek"
        (change)="changePeriod($event)"
    ></fp-period-selection>
</div>

<ng-container *ngIf="isLoading$ | async; else loaded">
    <div class="loading">
        <span class="loading-text">{{ 'employee_facilities.dashboard_loading' | translate }}</span>
        <img src="./assets/timesheets/timesheets-placeholder.png" width="100%" />
    </div>
</ng-container>
<ng-template #loaded>
    <ng-container *ngIf="(organisationStatements$ | async)?.length; else noData">
        <div class="timesheet-dashboard">
            <div class="timesheet-statements">
                <fp-list-timesheet-statements
                    [organisationStatements]="organisationStatements$ | async"
                    (goToVersion)="navigateToVersions($event)"
                ></fp-list-timesheet-statements>
            </div>
        </div>
    </ng-container>
    <ng-template #noData>
        <div class="no-data">
            {{ 'employee_facilities.no_dashboard_data' | translate }}
        </div>
    </ng-template>
</ng-template>

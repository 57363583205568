import { Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileService } from '../../_services/file.service';

@Component({
    selector: 'fp-image-uploader',
    templateUrl: 'image-uploader.component.html',
    styleUrls: ['image-uploader.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ImageUploaderComponent),
            multi: true,
        },
    ],
})
export class ImageUploaderComponent implements ControlValueAccessor {
    @Input() imageUrl;
    @Output() imageChange = new EventEmitter<File>();
    @HostBinding('class') classes = '';
    @Input() set isSmall(value) {
        this.classes = value ? 'small' : '';
    }
    @Input() maxWidth: number;
    @Input() maxHeight: number;
    @Input() width: number;
    @Input() height: number;
    @Input() imageObjectPosition = 'left';
    @ViewChild('image') imageComponent: ElementRef;

    public $isCropped: Observable<boolean>;

    onChange: any = () => {};
    onTouch: any = () => {};

    constructor(private imageService: FileService) {}

    writeValue(url: any): void {
        this.imageUrl = url;
        this.$isCropped = this.imageService.getImageSize(url).pipe(
            map((size) => {
                if (!this.maxHeight || !this.maxWidth) {
                    return false;
                }

                const scaleFactor = size.height > this.maxHeight ? this.maxHeight / size.height : 1;
                const scaledWidth = size.width * scaleFactor;

                return scaledWidth > this.maxWidth;
            })
        );

        this.onChange(url);
        this.onTouch(url);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {}

    onImageSelected(e) {
        const file: File = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.writeValue(reader.result);
                this.imageChange.emit(file);
            };
        }
    }
}

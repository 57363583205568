import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../_services';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthService,
        private toastr: ToastrService,
        private translateService: TranslateService,
        private router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                // 401 UNAUTHORIZED - The request has not been applied because it lacks valid
                //     authentication credentials for the target resource.
                if (err.status === 401) {
                    console.log('Logout because 401 response returned from api');
                    this.authenticationService.logout();
                }

                // 400 BAD REQUEST - The server cannot or will not process the request due to
                //     something that is perceived to be a client error (e.g., malformed request
                //     syntax, invalid request message framing, or deceptive request routing).
                // 404 NOT FOUND - The origin server did not find a current representation for
                //     the target resource or is not willing to disclose that one exists.
                if (err.status === 400 || err.status === 404) {
                    if (err.error.redirectUrl) {
                        const qps = { error: err.error.message, ...err.error.translateParams };
                        this.router.navigate([err.error.redirectUrl], { queryParams: qps });
                    }

                    err.error.warnings.forEach((x: string) => {
                        this.showWarning(x);
                    });
                }

                // 403 FORBIDDEN - The server understood the request but refuses to authorize it.
                if (err.status === 403) {
                    this.showWarning('shared.messages.unauthorized');
                }

                // 500 INTERNAL SERVER ERROR - The server encountered an unexpected condition that
                //     prevented it from fulfilling the request.
                if (err.status === 500) {
                    this.showWarning('shared.messages.external-systems-fail');
                }

                // 503 SERVICE UNAVAILABLE - The server is currently unable to handle the request
                //     due to a temporary overload or scheduled maintenance, which will likely be
                //     alleviated after some delay.
                if (err.status === 503) {
                    if (err.error.warnings) {
                        err.error.warnings.forEach((x: any) => {
                            this.showWarning(x);
                        });
                    } else {
                        this.showWarning('shared.messages.page-not-available');
                    }
                }

                return throwError(err);
            })
        );
    }

    showWarning(x: any) {
        const { message = x, ...translateParams } = x;

        this.translateService.get(message, translateParams).subscribe((text) => this.toastr.warning(text, '', { enableHtml: true }));
    }
}

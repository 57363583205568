import { createReducer, on } from '@ngrx/store';
import { EmployeeFacilitiesDashboardState } from '.';
import { groupByOrganisation } from '../functions/group-by-ogranisation';
import { EmployeeTimesheetStatementHistory } from '../models';
import * as fromActions from './employee-facilities-dashboard.actions';

export const initialState: EmployeeFacilitiesDashboardState = {
    loading: false,
    statements: [],
    organisationStatements: [],
    statementHistory: null as EmployeeTimesheetStatementHistory,
    selectedWeek: '',
};

export const reducer = createReducer(
    initialState,
    on(fromActions.LoadEmployeeTimesheetStatements, fromActions.LoadStatementVersion, (state) => ({
        ...state,
        loading: true,
    })),
    on(fromActions.LoadEmployeeTimesheetStatementsSuccess, (state, { statements }) => ({
        ...state,
        statements,
        organisationStatements: groupByOrganisation(statements),
        loading: false,
    })),
    on(fromActions.LoadStatementVersionSuccess, (state, { statementHistory }) => ({
        ...state,
        statementHistory,
        loading: false,
    })),
    on(fromActions.LoadEmployeeTimesheetStatementsFailure, fromActions.LoadStatementVersionFailure, (state) => ({
        ...state,
        loading: false,
    })),
    on(fromActions.SetSelectedWeek, (state, { selectedWeek }) => ({
        ...state,
        selectedWeek,
    }))
);

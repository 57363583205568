import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { FeatureFlags, FeatureFlagsService } from '../../shared/_services/feature-flags.service';
import { Role } from '../_models/role';
import { AuthService } from '../_services';
import { TenantFeatureDto } from './../../rest/model/tenantFeatureDto';
import { ApplicationName } from './../../shared/_models/application-name';

class RouteRedirect {
    isAllowed: boolean;
    redirectTo?: string;
}

@Injectable({ providedIn: 'root' })
export class IsFeatureFlagEnabledGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router, private featureFlagsService: FeatureFlagsService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.featureFlagsService.tenantFeatures$.pipe(
            map((tf) => {

                if(this.authService.currentUserValue == null){
                    // If the user is logged out, the normal AuthGuard should redirect the user to the login page.
                    return false;
                }
                const useNewTimesheet = this.isFeatureEnabled(FeatureFlags.useNewTimesheet, tf);
                const routeRedirect = this.isRouteAllowedByUseNewTimesheetFeatureFlag(
                    useNewTimesheet,
                    state.url,
                    this.authService.currentUserValue.role
                );

                if (!routeRedirect.isAllowed) {
                    console.log(`You are not authorized to view ${state.url}`);
                    this.router.navigate([routeRedirect.redirectTo]);
                }

                return routeRedirect.isAllowed;
            })
        );
    }

    isRouteAllowedByUseNewTimesheetFeatureFlag(useNewTimesheet: boolean, url: string, role: Role): RouteRedirect {
        const newTimesheetsUrl = '/timesheets';
        const oldOrganisationTimesheetUrl = `/nl/tab/${ApplicationName.RelationPortal}/OrganisationTimesheet`;
        const oldFlexworkerTimesheetUrl = `/nl/tab/${ApplicationName.FlexworkerPortal}/FlexworkerTimesheet`;

        // New organisation timesheet
        if (role === Role.Contact && url.includes(newTimesheetsUrl))
            return { isAllowed: useNewTimesheet, redirectTo: oldOrganisationTimesheetUrl };

        // Old organisation timesheet
        if (role === Role.Contact && url.includes(oldOrganisationTimesheetUrl))
            return { isAllowed: useNewTimesheet === false, redirectTo: newTimesheetsUrl };

        // New flexworker timesheet
        if (role === Role.Flexworker && url.includes(newTimesheetsUrl))
            return { isAllowed: useNewTimesheet, redirectTo: oldFlexworkerTimesheetUrl };

        // Old flexworker timesheet
        if (role === Role.Flexworker && url.includes(oldFlexworkerTimesheetUrl))
            return { isAllowed: useNewTimesheet === false, redirectTo: newTimesheetsUrl };

        // All other cases
        return { isAllowed: true, redirectTo: null };
    }

    isFeatureEnabled(featureId: string, tenantFeatures: TenantFeatureDto[]): boolean {
        return tenantFeatures.some((f) => f.featureId === featureId && f.isEnabled);
    }
}

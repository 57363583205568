import { AppActions, AppActionTypes } from './app.actions';
import { State } from './index';

export const initialState: State = {
    pageMenuTabs: [],
};

export function reducer(state = initialState, action: AppActions): State {
    switch (action.type) {
        case AppActionTypes.LoadPageMenuTabsSuccess:
            return { ...state, pageMenuTabs: action.payload };
        default:
            return state;
    }
}

<div class="modal-header modal-block-primary">
  <h4 class="modal-title">{{ 'shared-confirm-delete.delete-item' | translate }}</h4>
</div>
<div class="modal-body clearfix">
  <div class="modal-text">
    <p>{{ 'shared-confirm-delete.are-you-sure-you-want-to-delete-this-item' | translate }}</p>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-danger" (click)="action(true)">{{ 'shared.yes' | translate }}</button>
  <button class="btn btn-secondary right" (click)="action(false)">{{ 'shared.no' | translate }}</button>
</div>

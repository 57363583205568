import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = this.authService.currentUserValue;
        if (currentUser && currentUser.jwtToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.jwtToken}`,
                },
            });
        }

        return next.handle(request);
    }
}

/* 

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '../_services';
import { JwtHelperService } from '@auth0/angular-jwt';
import moment from 'moment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private jwtHelper = new JwtHelperService();

    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = this.authService.currentUserValue;
        if (request.url.toLocaleUpperCase().includes('REFRESHJWTTOKEN') === false && currentUser && currentUser.jwtToken) {
            // Check if the JWT token is expired or will epire soon. If so, try to get a
            // new JWT token using the refresh token.

            // if the JTW token expires within 5 minutes.
            //  REFRESH   REFRESH   USE CURRENT JWT
            //          ^         ^
            //          |         |
            // ........NOW...5 MIN LATER...
            const expirationDate = this.jwtHelper.getTokenExpirationDate();
            if (expirationDate < moment(new Date()).add(5, 'm').toDate()) {
                this.authService.refreshJwtToken().subscribe(() => {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${currentUser.jwtToken}`,
                        },
                    });

                    return next.handle(request);
                });
            }

            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.jwtToken}`,
                },
            });
        }

        return next.handle(request);
    }
}

*/

import { UpdateAppFaviconResponse } from './../../rest/model/updateAppFaviconResponse';
import { UpdateAppThemeDto } from '../_models/updateAppThemeDto';
import { UpdateAppFaviconDto } from '../_models/updateAppFaviconDto';
import { EditAppThemeDto } from '../_models/editAppThemeDto';
import { Favicon } from '../../rest/model/favicon';
import { PayloadAction, OriginAction } from '../../state/payload.interfaces';

export enum ThemesActionTypes {
    ThemeChanged = '[Themes] Theme Changed',

    Load = '[Themes] Load',
    LoadSuccess = '[Themes] Load Success',
    LoadFailed = '[Themes] Load Failed',

    LoadSpecifications = '[Themes] Load Specifications',
    LoadSpecificationsSuccess = '[Themes] Load Specifications Success',
    LoadSpecificationsFailed = '[Themes] Load Specifications Failed',

    UpdateSpecifications = '[Themes] Update Specifications',
    UpdateSpecificationsSuccess = '[Themes] Update Specifications Success',
    UpdateSpecificationsFailed = '[Themes] Update Specifications Failed',

    UpdateFavicon = '[Themes] Update Favicon',
    UpdateFaviconSuccess = '[Themes] Update Favicon Success',
    UpdateFaviconFailed = '[Themes] Update Favicon Failed',

    EditTheme = '[Themes] Edit Theme',

    DeleteInternetPortalTheme = '[Themes] Delete Internet Portal Theme',
    DeleteInternetPortalThemeSuccess = '[Themes] Delete Internet Portal Theme Success',
    DeleteInternetPortalThemeFailed = '[Themes] Delete Internet Portal Theme Failed'
}

export class ThemeChanged implements PayloadAction<Theme> {
    readonly type = ThemesActionTypes.ThemeChanged;
    constructor(public origin: string, public payload: Theme) { }
}

export class Load implements OriginAction {
    readonly type = ThemesActionTypes.Load;
    constructor(public origin: string) { }
}
export class LoadSuccess implements PayloadAction<Theme> {
    readonly type = ThemesActionTypes.LoadSuccess;
    constructor(public origin: string, public payload: Theme) { }
}
export class LoadFailed implements OriginAction {
    readonly type = ThemesActionTypes.LoadFailed;
    constructor(public origin: string) { }
}

export class LoadSpecifications implements OriginAction {
    readonly type = ThemesActionTypes.LoadSpecifications;
    constructor(public origin: string) { }
}
export class LoadSpecificationsSuccess implements PayloadAction<ThemeSpecifications> {
    readonly type = ThemesActionTypes.LoadSpecificationsSuccess;
    constructor(public origin: string, public payload: ThemeSpecifications) { }
}
export class LoadSpecificationsFailed implements OriginAction {
    readonly type = ThemesActionTypes.LoadSpecificationsFailed;
    constructor(public origin: string) { }
}

export class UpdateSpecifications implements PayloadAction<UpdateAppThemeDto> {
    readonly type = ThemesActionTypes.UpdateSpecifications;
    constructor(public origin: string, public payload: UpdateAppThemeDto) { }
}
export class UpdateSpecificationsSuccess implements PayloadAction<Theme> {
    readonly type = ThemesActionTypes.UpdateSpecificationsSuccess;
    constructor(public origin: string, public payload: Theme) { }
}
export class UpdateSpecificationsFailed implements OriginAction {
    readonly type = ThemesActionTypes.UpdateSpecificationsFailed;
    constructor(public origin: string) { }
}

export class UpdateFavicon implements PayloadAction<UpdateAppFaviconDto> {
    readonly type = ThemesActionTypes.UpdateFavicon;
    constructor(public origin: string, public payload: UpdateAppFaviconDto) { }
}
export class UpdateFaviconSuccess implements PayloadAction<UpdateAppFaviconResponse> {
    readonly type = ThemesActionTypes.UpdateFaviconSuccess;
    constructor(public origin: string, public payload: UpdateAppFaviconResponse) { }
}
export class UpdateFaviconFailed implements OriginAction {
    readonly type = ThemesActionTypes.UpdateFaviconFailed;
    constructor(public origin: string) { }
}

export class EditTheme implements PayloadAction<EditAppThemeDto> {
    readonly type = ThemesActionTypes.EditTheme;
    constructor(public origin: string, public payload: EditAppThemeDto) { }
}

export class DeleteInternetPortalTheme implements PayloadAction<number> {
    readonly type = ThemesActionTypes.DeleteInternetPortalTheme;
    constructor(public origin: string, public payload: number) { }
}
export class DeleteInternetPortalThemeSuccess implements OriginAction {
    readonly type = ThemesActionTypes.DeleteInternetPortalThemeSuccess;
    constructor(public origin: string) { }
}
export class DeleteInternetPortalThemeFailed implements OriginAction {
    readonly type = ThemesActionTypes.DeleteInternetPortalThemeFailed;
    constructor(public origin: string) { }
}


export type ThemesActions =
    | ThemeChanged
    | Load
    | LoadSuccess
    | LoadFailed
    | LoadSpecifications
    | LoadSpecificationsSuccess
    | LoadSpecificationsFailed
    | UpdateSpecifications
    | UpdateSpecificationsSuccess
    | UpdateSpecificationsFailed
    | UpdateFavicon
    | UpdateFaviconSuccess
    | UpdateFaviconFailed
    | DeleteInternetPortalTheme
    | DeleteInternetPortalThemeSuccess
    | DeleteInternetPortalThemeFailed
    | EditTheme;

export interface Theme {
    logoUrl: string;
    favicons: Favicon[];
    headerColor: string;
    accentColor: string;
    menuItemId?: number;
}

export interface ThemeSpecification {
    logoUrl: string;
    headerColor: string;
    accentColor: string;
    menuItemId?: number;
    name: string;
    isGeneral: boolean;
}

export interface ThemeSpecifications {
    favicons: Favicon[];
    appThemes: ThemeSpecification[];
}

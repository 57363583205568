import { createReducer, on } from '@ngrx/store';
import { FeatureFlag } from 'src/app/rest';
import { SharedState } from '.';
import { GetFeatureResponse, GetFeaturesResponse } from '../../rest';
import { FeatureInfo } from '../../settings/models/featureInfo';
import { GeneralSetting } from './../../auth/_models/general-setting';
import { GetTenantFeaturesResponse } from './../../rest/model/getTenantFeaturesResponse';
import * as fromActions from './shared.actions';

export const initialState: SharedState = {
    generalSetting: null,
    generalSettingsSaving: false,
    featuresLoading: true,
    tenantFeaturesLoading: true,
    currentFeatureLoading: false,
    features: [],
    tenantFeatures: [],
    currentFeature: <FeatureInfo>{ flags: [] },
};

export const reducer = createReducer(
    initialState,
    on(fromActions.LoadGeneralSettings, (state: SharedState) => ({
        ...state,
        generalSetting: null,
    })),
    on(fromActions.LoadGeneralSettingsSuccess, (state: SharedState, payload: GeneralSetting) => {
        return {
            ...state,
            generalSetting: payload,
        };
    }),
    on(fromActions.LoadGeneralSettingsFailed, (state: SharedState) => {
        return {
            ...state,
            generalSetting: null,
        };
    }),
    on(fromActions.UpdateGeneralSettings, (state) => ({
        ...state,
        generalSettingsSaving: true,
    })),

    on(fromActions.UpdateGeneralSettingsSuccess, (state, { updateSettings }) => ({
        ...state,
        generalSettingsSaving: false,
        generalSettings: {
            ...state.generalSetting,
            smsAccessKey: updateSettings.smsAccessKey,
            smsOriginator: updateSettings.smsOriginator,
        },
    })),
    on(fromActions.UpdateGeneralSettingsFailed, (state) => ({
        ...state,
        generalSettingsSaving: false,
    })),
    on(fromActions.LoadAllFeatures, (state: SharedState, { reload }) => {
        if (reload) {
            return { ...state };
        }

        return {
            ...state,
            featuresLoading: true,
            features: [],
        };
    }),
    on(fromActions.LoadAllFeaturesSuccess, (state: SharedState, payload: GetFeaturesResponse) => {
        return {
            ...state,
            featuresLoading: false,
            features: payload.features,
        };
    }),
    on(fromActions.LoadAllFeaturesFailed, (state: SharedState) => {
        return {
            ...state,
            featuresLoading: false,
            features: [],
        };
    }),
    on(fromActions.LoadTenantFeatures, (state: SharedState) => {
        return {
            ...state,
            tenantFeaturesLoading: true,
            tenantFeatures: [],
        };
    }),
    on(fromActions.LoadTenantFeaturesSuccess, (state: SharedState, payload: GetTenantFeaturesResponse) => {
        return {
            ...state,
            tenantFeaturesLoading: false,
            tenantFeatures: payload.tenantFeatures,
        };
    }),
    on(fromActions.LoadTenantFeaturesFailed, (state: SharedState) => {
        return {
            ...state,
            tenantFeaturesLoading: false,
            tenantFeatures: [],
        };
    }),
    on(fromActions.LoadCurrentFeature, (state: SharedState) => {
        return {
            ...state,
            currentFeatureLoading: true,
            currentFeature: null,
        };
    }),
    on(fromActions.LoadCurrentFeatureSuccess, (state: SharedState, payload: GetFeatureResponse) => {
        return {
            ...state,
            currentFeatureLoading: false,
            currentFeature: <FeatureInfo>{ ...payload },
        };
    }),
    on(fromActions.LoadCurrentFeatureFailed, (state: SharedState) => {
        return {
            ...state,
            currentFeatureLoading: false,
            currentFeature: null,
        };
    }),
    on(fromActions.ChangeFeatureState, (state: SharedState, { isEnabled, isEnabledForAdmins, tenant, featureId }) => {
        const featureFlags = state.currentFeature.flags.map((f) => {
            if (f.tenant === tenant && state.currentFeature.id === featureId) {
                return { ...f, isEnabled, isEnabledForAdmins };
            }

            return f;
        });

        return {
            ...state,
            currentFeature: { ...state.currentFeature, flags: [...featureFlags] },
        };
    }),
    on(fromActions.EnableFeatureOnAllTenantsSuccess, (state: SharedState, { isEnabled, isEnabledForAdmins }) => {
        const featureFlags = state.currentFeature.flags.map((f: FeatureFlag) => {
            return { ...f, isEnabled: f.isEnabled || isEnabled, isEnabledForAdmins: f.isEnabledForAdmins || isEnabledForAdmins };
        });

        return {
            ...state,
            currentFeature: { ...state.currentFeature, flags: [...featureFlags] },
        };
    }),
    on(fromActions.FeatureStateChanged, (state: SharedState, { isEnabled, isEnabledForAdmins, featureId, tenant }) => {
        const featureFlags = state.currentFeature.flags.map((f) => {
            if (f.tenant === tenant && state.currentFeature.id === featureId) {
                return { ...f, isEnabled, isEnabledForAdmins };
            }

            return f;
        });

        const tenantFeatures = state.tenantFeatures.map((f) => {
            if (featureId === f.featureId) {
                return { ...f, isEnabled, isEnabledForAdmins };
            }

            return f;
        });

        return {
            ...state,
            tenantFeatures,
            currentFeature: { ...state.currentFeature, flags: [...featureFlags] },
        };
    }),
    on(fromActions.ChangeThisTenantFeatureState, (state: SharedState, { featureId, isEnabled, isEnabledForAdmins }) => {
        const tenantFeatures = state.tenantFeatures.map((f) => {
            if (featureId === f.featureId) {
                return { ...f, isEnabled, isEnabledForAdmins };
            }

            return f;
        });

        return {
            ...state,
            tenantFeatures,
        };
    })
);
